export default [
  {
    name: 'PrepressFileUploader',
    path: '/prepress/file-uploader',
    component: () => import('@/views/app/pages/prepress/uploader'),
    children: []
  },
  {
    name: 'PrepressJobListPage',
    path: '/prepress/jobs',
    meta: { listPage: 'jobs', listPageTitleKey: 'jobs' },
    component: () => import('@/views/app/pages/prepress/jobs/JobListPage')
  }
]
