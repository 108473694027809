<template>
  <div class="calculator d-flex justify-content-start align-items-center">
    <span v-if="!hideName" class="info mr-2">
      <!-- <i :id="'description_' + cf.name" class="simple-icon-calculator text-primary"></i> -->
      <span class="text-primary">{{ name }}:</span>
    </span>
    <span :class="calculatorClass">
      <span class="font-weight-bold">{{
          isMoney ? _intlCurrencyFormat(value) : percentage ? _intlPercentFormat(value) : _intlNumberFormat(value)
        }}</span>
    </span>
    <span class="ml-2 text-success-2" v-if="isVolumeDiscount && adjustmentAvailable && value > 0">
      <span class="font-weight-bold">0%</span>
    </span>
    <span class="ml-2 text-danger" v-else-if="usingDiscount && !marginHasDiscount">
      <span class="font-weight-bold">{{
          isMoney ? _intlCurrencyFormat(discountedPrice) : percentage ? _intlPercentFormat(discountedPrice) : _intlNumberFormat(discountedPrice)
        }}</span>
    </span>
    <span class="ml-2 text-danger" v-else-if="usingDiscount && marginHasDiscount">
      <span class="font-weight-bold">{{
          isMoney ? _intlCurrencyFormat(discountedTotalMargin) : percentage ? _intlPercentFormat(discountedTotalMargin) : _intlNumberFormat(discountedTotalMargin)
        }}</span>
    </span>
  </div>
</template>

<script>
import { isObject } from "lodash";

export default {
  props: {
    hideName: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: true
    },
    percentage: {
      type: Boolean,
      required: false,
      default: false
    },
    isMoney: {
      type: Boolean,
      required: false,
      default: false
    },
    moneySign: {
      type: String,
      required: false,
      default: "$"
    },
    name: {
      type: String,
      required: true
    },
    discountAmount: {
      type: Number,
      required: false,
      default: 0
    },
    discountablePrice: {
      type: Number,
      required: false,
      default: 0
    },
    disableDiscount: {
      type: Boolean,
      required: false,
      default: false
    },
    quantity: {
      type: Number,
      required: false,
      default: 1
    },
    margin: {
      type: Object,
      required: false,
      default: null
    },
    isVolumeDiscount: {
      type: Boolean,
      required: false,
      default: false
    },
    adjustmentAvailable: {
      type: Boolean,
      required: false,
      default: false
    },
    //! ************** Yeni Yapılan Yer ******************
    isTotalPriceSale: {
      type: Boolean,
      required: false,
      default: false
    },
    discountedUnitPrice: {
      type: Number,
      required: false,
      default: 0
    },
    totalQuantity: {
      type: Number,
      required: false,
      default: 0
    }
    //! ************** Yeni Yapılan Yer ******************
  },
  computed: {
    //!Eski Yapılan Yer
    // discountedPrice() {
    //   if (this.usingDiscount && this.discountablePrice) {
    //     let discountedPrice = this.discountablePrice * (this.discountAmount / 100);
    //     discountedPrice = discountedPrice / this.quantity;
    //     return this.value - discountedPrice;
    //   }
    // },
    discountedPrice() {
      //! Yeni Yapılan Yer
      if (this.usingDiscount && this.discountablePrice) {
        if (this.isTotalPriceSale) {
          return (this.discountedUnitPrice.toFixed(2) * this.totalQuantity)
        } else {
          let discountedPrice = this.discountablePrice * (this.discountAmount / 100);
          discountedPrice = discountedPrice / this.quantity;
          return this.value - discountedPrice;
        }
      }
    },
    usingDiscount() {
      return !this.disableDiscount && this.discountAmount && this.discountAmount > 0 && this.discountablePrice > 0;
    },
    marginHasDiscount() {
      return isObject(this.margin) && this.usingDiscount;
    },
    discountedTotalMargin() {
      if (this.marginHasDiscount) {
        const { total_price, cost_price } = this.margin;
        const discount_amount = (this.discountablePrice * (this.discountAmount / 100)) / this.quantity;
        const discounted_total_price = total_price - discount_amount;
        return ((discounted_total_price - cost_price) / discounted_total_price) * 100;
      }
    },
    calculatorClass() {
      return {
        "lineThrough": this.usingDiscount || (this.isVolumeDiscount && this.adjustmentAvailable && this.value > 0),
        "text-danger": this.isVolumeDiscount && this.value > 0,
        "text-success": this.isVolumeDiscount && this.value < 0
      };
    }
  }
};
</script>
