import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/clients/",
    key: "client",
    fetchKey: "clients", // for getting all records from API. clients : [{...},{...},{...}]
    permissionKey: "",
    catalog_filter_settings_mapping: {
      inventory_settings: "inventory_product",
      accounting_invoice_final_sale: "accounting_invoice",
      accounting_invoice_proforma_sale: "accounting_proforma_invoice",
      accounting_invoice_quote_sale: "accounting_quote_invoice",
      accounting_invoice_final_purchase: "accounting_invoice_final_purchase",
      accounting_invoice_proforma_purchase: "accounting_purchase_order",
      // accounting_invoice_final_sale: "accounting_invoice_final_sale",
      // accounting_invoice_proforma_sale: "accounting_invoice_proforma_sale",
      // accounting_invoice_quote_sale: "accounting_invoice_quote_sale",
      // accounting_invoice_final_purchase: "accounting_invoice_final_purchase",
      // accounting_invoice_proforma_purchase: "accounting_invoice_proforma_purchase",
      contact_customer: "contact_customer",
      contact_supplier: "contact_supplier",
      logistic_shipment: "logistic_shipment",
      logistic_transfer: "logistic_transfer",
      logistic_fulfillment: "logistic_fulfillment"
    },
    settings: [
      //* Contact, Contact Customer, Contact Supplier
      {
        title: "Contact Settings",
        key: "contact_settings",
        module: "contact_settings",
        icon: "fa fa-3x fa-users",
        user_types: ["puresol"],
        showsAt: ["contact_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          }
        ]
      },
      {
        title: "Customer Settings",
        key: "contact_customer",
        module: "contact_customer",
        icon: "fa fa-3x fa-users",
        showsAt: [],
        user_types: ["puresol"],
        depends_on: ["contact_settings"],
        sub_settings: [
          {
            title: "Catalog Settings",
            key: "catalog"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          }
        ]
      },
      {
        title: "Supplier Settings",
        key: "contact_supplier",
        module: "contact_supplier",
        icon: "fa fa-3x fa-users",
        showsAt: [],
        user_types: ["puresol"],
        depends_on: ["contact_settings"],
        sub_settings: [
          {
            title: "Catalog Settings",
            key: "catalog"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          }
        ]
      },
      //! SETTINGS YOK : Marketing Settings
      {
        title: "Marketing Settings",
        key: "marketing_settings",
        module: "marketing_settings",
        icon: "fa fa-3x fa-bullhorn",
        showsAt: ["marketing_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Gift Card",
            key: "gift_card"
          },
          {
            title: "Loyalty",
            key: "loyalty"
          },
          {
            title: "Referral",
            key: "referral"
          },
          {
            title: "Discount",
            key: "discount"
          },
          {
            title: "Redeem Reward Rules",
            key: "redeem_reward_rules"
          }
        ]
      },

      //* Hive Settings
      {
        title: "Hive Settings",
        key: "hive_settings",
        module: "hive_settings",
        icon: "fa fa-3x fa-connectdevelop",
        showsAt: ["hive_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings",
            webmaster_only: true
          },
          {
            title: "Project Settings",
            key: "project_settings",
            showsAt: ["hive_project"]
          },
          {
            title: "Quote Settings",
            key: "quote_settings",
            showsAt: ["hive_quote"]
          },
          {
            title: "Work Order Settings",
            key: "work_order_settings",
            showsAt: ["hive_project"]
          },
          {
            title: "Job Settings",
            key: "job_settings",
            showsAt: ["hive_project"]
          }
        ]
      },
      //* Inventory Settings
      {
        title: "Inventory Settings",
        key: "inventory_settings",
        module: "inventory_settings",
        icon: "simpleicon_icon simple-icon-organization",
        showsAt: ["inventory_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Product Settings",
            key: "product"
          },
          {
            title: "Product Re-Pack",
            key: "product_re_pack"
          },
          {
            title: "Product Reserving",
            key: "product_reserving"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },

      {
        title: "Logistic Settings",
        key: "logistic_settings",
        module: "logistic_settings",
        icon: "iconsmind_icon iconsmind-Ship-2",
        showsAt: ["logistic_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings",
            webmaster_only: true
          }
        ]
      },
      {
        title: "Shipment Settings",
        key: "logistic_shipment",
        module: "logistic_shipment",
        icon: "iconsmind_icon iconsmind-Ship",
        // showsAt: ["logistic_settings.logistic_shipment"],
        showsAt: ["logistic_settings"],
        user_types: ["puresol"],
        depends_on: ["logistic_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      {
        title: "Fulfillment Settings",
        key: "logistic_fulfillment",
        module: "logistic_fulfillment",
        icon: "iconsmind_icon iconsmind-Bus",
        // showsAt: ["logistic_settings.logistic_fulfillment"],
        showsAt: ["logistic_settings"],
        user_types: ["puresol"],
        depends_on: ["logistic_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      {
        title: "Quick Fulfillment Settings",
        key: "logistic_quick_fulfillment",
        module: "logistic_quick_fulfillment",
        icon: "iconsmind_icon iconsmind-Bus",
        // showsAt: ["logistic_settings.logistic_quick_fulfillment"],
        showsAt: ["logistic_settings"],
        user_types: ["puresol"],
        depends_on: ["logistic_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          }
        ]
      },
      {
        title: "Transfers Settings",
        key: "logistic_transfer",
        module: "logistic_transfer",
        icon: "simpleicon_icon simple-icon-rocket",
        showsAt: ["logistic_settings"],
        user_types: ["puresol"],
        // showsAt: ["logistic_settings.logistic_transfer"],
        depends_on: ["logistic_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      //* TPA Settings
      {
        title: "TPA Settings",
        key: "tpa_settings",
        module: "tpa_settings",
        icon: "iconsmind_icon iconsmind-Cube-Molecule2",
        showsAt: ["tpa_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
        ]
      },
      //* Accounting Settings.
      {
        title: "Accounting Settings",
        key: "accounting_settings",
        module: "accounting_settings",
        icon: "fa fa-3x fa-money",
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Currency Rates",
            key: "currency_rates",
            depends_on: ["multi_currency_enabled"]
          }
        ]
      },
      // Accounting invoice Final Sale
      {
        title: "Invoice Settings",
        key: "accounting_settings",
        module: "accounting_invoice_final_sale",
        icon: "iconsmind_icon iconsmind-Money-2",
        // showsAt: ["accounting_settings.invoice"],
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "invoice"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      // Accounting invoice Proforma Sale
      {
        title: "Proforma Invoice Settings",
        key: "accounting_settings",
        module: "accounting_invoice_proforma_sale",
        icon: "iconsmind_icon iconsmind-Money-2",
        // showsAt: ["accounting_settings.proforma_invoice"],
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "proforma_invoice"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      // Accounting invoice Proforma Sale
      {
        title: "Accounting Quote Settings",
        key: "accounting_settings",
        module: "accounting_invoice_quote_sale",
        icon: "QuotePriceIcon",
        svg: true,
        // showsAt: ["accounting_settings.quote"],
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "quote"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      // Accounting invoice Proforma Purchase
      {
        title: "Purchase Order Settings",
        key: "accounting_settings",
        module: "accounting_invoice_proforma_purchase",
        icon: "simpleicon_icon simple-icon-basket-loaded",
        // showsAt: ["accounting_settings.purchase_order"],
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "purchase_order"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      // Accounting invoice Final Purchase
      {
        title: "Bill Settings",
        key: "accounting_settings",
        module: "accounting_invoice_final_purchase",
        icon: "simpleicon_icon simple-icon-basket-loaded",
        // showsAt: ["accounting_settings.bill"],
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "bill"
          },
          {
            title: "Filter Item Settings",
            key: "filter_items"
          },
          {
            title: "Catalog Settings",
            key: "catalog"
          }
        ]
      },
      {
        title: "POS Settings",
        key: "accounting_settings",
        module: "accounting_settings",
        icon: "fa fa-3x fa-hand-o-up",
        // showsAt: ["accounting_settings.pos"],
        showsAt: [false],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "pos"
          }
        ]
      },
      {
        title: "Karavan Settings",
        key: "karavan_settings",
        module: "karavan_settings",
        icon: "fa fa-3x fa-bus",
        showsAt: ["karavan_settings"],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings",
            webmaster_only: true
          }
        ]
      },
      {
        title: "Timezone Settings",
        key: "timezone_settings",
        module: "timezone_settings",
        icon: "fa fa-3x fa-globe",
        showsAt: [false],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          }
        ]
      },
      {
        title: "QuickBook Settings",
        key: "quickbooks_settings",
        module: "quickbooks_settings",
        icon: "QuickBookIcon",
        svg: true,
        showsAt: ["accounting_settings"],
        user_types: ["puresol"],
        depends_on: ["accounting_settings"],
        sub_settings: [
          {
            title: "Accounts Integration",
            key: "accounts_integration_settings"
          },
          {
            title: "Payment Methods",
            key: "payment_methods_settings"
          },
          {
            title: "Payment Terms",
            key: "payment_terms_settings"
          }
        ]
      },
      {
        title: "Prepress Settings",
        key: "prepress_settings",
        module: "prepress_settings",
        icon: "PrepressFavicon",
        svg: true,
        style: {fill: "#000"},
        showsAt: ["prepress_settings"],
        user_types: ["puresol", "prepress"],
        depends_on: ["prepress_settings"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings",
            webmaster_only: false
          },
          {
            title: "Preflight Profiles",
            key: "preflight_profiles"
          },
        ]
      },
      {
        title: "Client Settings",
        key: "client",
        module: "currency_settings",
        icon: "fa fa-3x fa-cogs",
        showsAt: [],
        user_types: ["puresol"],
        sub_settings: [
          {
            title: "General Settings",
            key: "general_settings"
          },
          {
            title: "Currency Settings",
            key: "currency_settings",
            showsAt: ["accounting_currency_rate"]
          },
          {
            title: "UI Labels",
            key: "ui_labels"
          },
          {
            title: 'Mail Settings',
            key: 'mail_settings'
          }
        ]
      }
    ],
    marketing_settings: {
      accumulate_amount_type: [
        {
          name: "money",
          text: i18n.t("pages.client_settings.marketing_settings.enums.accumulate_amount_type.money")
        },
        {
          name: "point",
          text: i18n.t("pages.client_settings.marketing_settings.enums.accumulate_amount_type.point")
        }
      ],
      trigger: [
        {
          name: "any_sale",
          text: i18n.t("pages.client_settings.marketing_settings.enums.trigger.any_sale")
        },
        {
          name: "product_sale",
          text: i18n.t("pages.client_settings.marketing_settings.enums.trigger.product_sale")
        },
        {
          name: "product_type_sale",
          text: i18n.t("pages.client_settings.marketing_settings.enums.trigger.product_type_sale")
        }
      ],
      apply_order: [
        {
          name: "default",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_order.default")
        },
        {
          name: "additional_to_default",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_order.additional_to_default")
        },
        {
          name: "instead_of_default",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_order.instead_of_default")
        }
      ],
      apply_on: [
        {
          name: "all_invoice",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_on.all_invoice")
        },
        {
          name: "all_invoice_ex_shipping",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_on.all_invoice_ex_shipping")
        },
        {
          name: "on_shipping",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_on.on_shipping")
        },
        {
          name: "products",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_on.products")
        },
        {
          name: "product_types",
          text: i18n.t("pages.client_settings.marketing_settings.enums.apply_on.product_types")
        }
      ],
      amount_type: [
        {
          name: "fixed",
          text: i18n.t("pages.client_settings.marketing_settings.enums.amount_type.fixed")
        },
        {
          name: "percentage",
          text: i18n.t("pages.client_settings.marketing_settings.enums.amount_type.percentage")
        },
        {
          name: "free",
          text: i18n.t("pages.client_settings.marketing_settings.enums.amount_type.free")
        }
      ],
      repetition: [
        {
          name: "one_time",
          text: i18n.t("pages.client_settings.marketing_settings.enums.repetition.one_time")
        },
        {
          name: "infinite",
          text: i18n.t("pages.client_settings.marketing_settings.enums.repetition.infinite")
        },
        {
          name: "specific_amount",
          text: i18n.t("pages.client_settings.marketing_settings.enums.repetition.specific_amount")
        }
      ]
    },
    inventory_settings: {
      stock_type: [
        {
          name: "single_unit",
          id: "single_unit",
          text: i18n.t("pages.client_settings.inventory_settings.enums.stock_type.single_unit"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.stock_type.single_unit"),
        },
        {
          name: "multiple_units",
          id: "multiple_units",
          text: i18n.t("pages.client_settings.inventory_settings.enums.stock_type.multiple_units"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.stock_type.multiple_units"),
        }
      ],
      quantity_type: [
        {
          name: "unit",
          id: "unit",
          text: i18n.t("pages.client_settings.inventory_settings.enums.quantity_type.unit"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.quantity_type.unit")
        },
        {
          name: "metage",
          id: "metage",
          text: i18n.t("pages.client_settings.inventory_settings.enums.quantity_type.metage"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.quantity_type.metage")
        }
      ],
      filter_items: {
        field_type: [
          {
            name: "field",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type.field")
          },
          {
            name: "measurement",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type.measurement")
          },
          {
            name: "custom_field",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type.custom_field")
          }
        ],
        filter_type: [
          {
            name: "equal",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.filter_type.equal")
          },
          {
            name: "range",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.filter_type.range")
          },
          {
            name: "includes",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.filter_type.includes")
          },
          {
            name: "checkbox",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.filter_type.checkbox")
          }
        ],
        placement: [
          {
            name: "basic",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.placement.basic")
          },
          {
            name: "advanced",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.placement.advanced")
          }
        ],
        field_type_measurements: [
          {
            name: "area",
            id: "area",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.area"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.area")
          },
          {
            name: "height",
            id: "height",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.height"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.height")
          },
          {
            name: "width",
            id: "width",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.width"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.width")
          },
          {
            name: "length",
            id: "length",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.length"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.length")
          },
          {
            name: "diameter",
            id: "diameter",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.diameter"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.diameter")
          },
          {
            name: "core_diameter",
            id: "core_diameter",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.core_diameter"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.core_diameter")
          },
          {
            name: "weight",
            id: "weight",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.weight"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.weight")
          },
          {
            name: "thickness",
            id: "thickness",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.thickness"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.thickness")
          },
          {
            name: "basis_weight",
            id: "basis_weight",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.basis_weight"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.basis_weight")
          },
          {
            name: "package_height",
            id: "package_height",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_height"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_height")
          },
          {
            name: "package_width",
            id: "package_width",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_width"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_width")
          },
          {
            name: "package_length",
            id: "package_length",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_length"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_length")
          },
          {
            name: "package_weight",
            id: "package_weight",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_weight"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.package_weight")
          },

          {
            name: "unit_count",
            id: "unit_count",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.unit_count"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.unit_count")
          },
          {
            name: "box_count",
            id: "box_count",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.box_count"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.box_count")
          },
        ],
        field_type_fields: [
          {
            name: "status",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.status")
          },
          {
            name: "type",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.type")
          },
          {
            name: "text",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.text")
          },
          {
            name: "location",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.location")
          },
          {
            name: "is_reserved",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.is_reserved")
          },
          {
            name: "reserved_contact",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.reserved_contact")
          },
          {
            name: "reserved_invoice",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.reserved_invoice")
          },
          {
            name: "reserved_shipment",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.reserved_shipment")
          },
          {
            name: "supplier",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.supplier")
          },
          {
            name: "tpa_source_id",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.tpa_source_id")
          },
          {
            name: "tpa_source_reference_id",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_fields.tpa_source_reference_id")
          }
        ]
      },
      catalog: {
        type: [
          {
            name: "static_field",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.type.static_field")
          },
          {
            name: "measurement",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.type.measurement")
          },
          {
            name: "custom_field",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.type.custom_field")
          }
        ],
        field_type_static_names: [
          {
            name: "id",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.id")
          },
          {
            name: "product_type",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.product_type")
          },
          {
            name: "quantity",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.quantity")
          },
          {
            name: "title",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.title")
          },
          {
            name: "price",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.price")
          },
          {
            name: "status",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.status")
          },
          {
            name: "sku",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.sku")
          },
          {
            name: "barcode",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_static_names.barcode")
          }
        ],
        field_type_measurements: [
          {
            name: "area",
            id: "area",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.area"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.area")
          },
          {
            name: "height",
            id: "height",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.height"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.height")
          },
          {
            name: "width",
            id: "width",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.width"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.width")
          },
          {
            name: "length",
            id: "length",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.length"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.length")
          },
          {
            name: "diameter",
            id: "diameter",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.diameter"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.diameter")
          },
          {
            name: "core_diameter",
            id: "core_diameter",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.core_diameter"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.core_diameter")
          },
          {
            name: "weight",
            id: "weight",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.weight"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.weight")
          },
          {
            name: "thickness",
            id: "thickness",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.thickness"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.thickness")
          },
          {
            name: "basis_weight",
            id: "basis_weight",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.basis_weight"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.field_type_measurements.basis_weight")
          },
          {
            name: "unit_count",
            id: "unit_count",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.unit_count"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.unit_count")
          },
          {
            name: "box_count",
            id: "box_count",
            text: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.box_count"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.filter_items.field_type_measurements.box_count")
          },
        ],
        link: [
          {
            name: "none",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link.none")
          },
          {
            name: "edit",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link.edit")
          },
          {
            name: "view",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link.view")
          }
        ],
        link_target: [
          {
            name: "none",
            id: "none",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link_target.none"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link_target.none")
          },
          {
            name: "blank",
            id: "blank",
            text: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link_target.blank"),
            label: i18n.t("pages.client_settings.inventory_settings.enums.catalog.link_target.blank")
          }
        ]
      },
      stock_model: [
        {
          id: "m_1_unit",
          name: "m_1_unit",
          text: i18n.t("pages.client_settings.inventory_settings.enums.stock_model.m_1_unit"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.stock_model.m_1_unit"),
        },
        {
          id: "m_2_unit_metage",
          name: "m_2_unit_metage",
          text: i18n.t("pages.client_settings.inventory_settings.enums.stock_model.m_2_unit_metage"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.stock_model.m_2_unit_metage"),
        },
      ],
      inventory_types: [
        {
          name: "product",
          id: "product",
          value: "product",
          text: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.product"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.product")
        },
        {
          name: "gift_card",
          id: "gift_card",
          value: "gift_card",
          text: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.gift_card"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.gift_card")
        },
        {
          name: "accounting_item",
          id: "accounting_item",
          value: "accounting_item",
          text: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.accounting_item"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.accounting_item")
        },
        {
          name: "raw_material",
          id: "raw_material",
          value: "raw_material",
          text: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.raw_material"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.raw_material")
        },
        {
          name: "template",
          id: "template",
          value: "template",
          text: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.template"),
          label: i18n.t("pages.client_settings.inventory_settings.enums.inventory_types.template")
        }
      ]
    },
    accounting_settings: {
      billing_address_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      shipping_address_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      billing_location_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      shipping_location_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      shipments_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      transfers_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      invoice_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      proforma_invoice_options: [
        {
          name: "required",
          id: "required",
          text: i18n.t("pages.client_settings.enums.required"),
          label: i18n.t("pages.client_settings.enums.required")
        },
        {
          name: "optional",
          id: "optional",
          text: i18n.t("pages.client_settings.enums.optional"),
          label: i18n.t("pages.client_settings.enums.optional")
        },
        {
          name: "none",
          id: "none",
          text: i18n.t("pages.client_settings.enums.none"),
          label: i18n.t("pages.client_settings.enums.none")
        }
      ],
      create_fulfillment_with_options: [
        {
          name: "full_quantity",
          id: "full_quantity",
          text: i18n.t("pages.client_settings.accounting_settings.create_fulfillment_with_options.full_quantity"),
          label: i18n.t("pages.client_settings.accounting_settings.create_fulfillment_with_options.full_quantity"),
          default: true
        },
        {
          name: "available_location_quantity",
          id: "available_location_quantity",
          text: i18n.t("pages.client_settings.accounting_settings.create_fulfillment_with_options.available_location_quantity"),
          label: i18n.t("pages.client_settings.accounting_settings.create_fulfillment_with_options.available_location_quantity")
        },
        {
          name: "available_quantity",
          id: "available_quantity",
          text: i18n.t("pages.client_settings.accounting_settings.create_fulfillment_with_options.available_quantity"),
          label: i18n.t("pages.client_settings.accounting_settings.create_fulfillment_with_options.available_quantity")
        }
      ],
      credits_belong_tos_options: [
        {
          id: "invoice",
          label: i18n.t("pages.client_settings.accounting_settings.credits_belong_tos_options.invoice"),
          default: false
        },
        {
          id: "contact",
          label: i18n.t("pages.client_settings.accounting_settings.credits_belong_tos_options.contact"),
          default: false
        },
      ],
      default_status_options: [
        {
          id: "active",
          label: i18n.t("pages.client_settings.accounting_settings.default_status_options.active"),
          default: true
        },
        {
          id: "pending",
          label: i18n.t("pages.client_settings.accounting_settings.default_status_options.pending"),
          default: false
        },
      ],
    },
    logistic_settings: {
      shipmentTypes: [
        {
          name: "fulfillment",
          text: i18n.t("pages.client_settings.logistic_settings.enums.shipmentTypes.fulfillment")
        },
        {
          name: "quick_fulfillment",
          text: i18n.t("pages.client_settings.logistic_settings.enums.shipmentTypes.quick_fulfillment")
        },
        {
          name: "shipment",
          text: i18n.t("pages.client_settings.logistic_settings.enums.shipmentTypes.shipment")
        },
        {
          name: "transfer",
          text: i18n.t("pages.client_settings.logistic_settings.enums.shipmentTypes.transfer")
        }
      ]
    },
    contact_settings: {
      preferred_languages: [
        {
          label: i18n.t("pages.client_settings.contact_settings.enums.preferred_languages.en"),
          id: "en"
        },
        {
          label: i18n.t("pages.client_settings.contact_settings.enums.preferred_languages.fr"),
          id: "fr"
        }
      ]
    },
    client: {
      currency_settings: {
        multi_currency_rate_models: [
          {
            id: "fixed",
            label: i18n.t("pages.client_settings.client.currency_settings.enums.multi_currency_rate_models.fixed")
          },
          {
            id: "flex",
            label: i18n.t("pages.client_settings.client.currency_settings.enums.multi_currency_rate_models.flex")
          }
        ]
      },
      ui_labels: {
        languages: [
          {
            id: "en",
            label: i18n.t("pages.client_settings.client.ui_labels.enums.languages.en")
          },
          {
            id: "fr",
            label: i18n.t("pages.client_settings.client.ui_labels.enums.languages.fr")
          }
        ]
      }
    },
    catalog_items: {},
    filter_items: {},
    filter_field_names: {
      id: i18n.t("pages.client_settings.client.field_names.id"),
      text: i18n.t("pages.client_settings.client.field_names.text"),
      tpa_tpa_id: i18n.t("pages.client_settings.client.field_names.tpa_tpa_id"),
      tpa_tpa_reference_id: i18n.t("pages.client_settings.client.field_names.tpa_tpa_reference_id"),
      phone: i18n.t("pages.client_settings.client.field_names.phone"),
      street1: i18n.t("pages.client_settings.client.field_names.street1"),
      city: i18n.t("pages.client_settings.client.field_names.city"),
      country_state: i18n.t("pages.client_settings.client.field_names.country_state"),
      zipcode: i18n.t("pages.client_settings.client.field_names.zipcode"),
      contact_person_first_name: i18n.t("pages.client_settings.client.field_names.contact_person_first_name"),
      contact_person_last_name: i18n.t("pages.client_settings.client.field_names.contact_person_last_name"),
      user_representative: i18n.t("pages.client_settings.client.field_names.user_representative"),
      rating: i18n.t("pages.client_settings.client.field_names.rating"),
      product_select: i18n.t("pages.client_settings.client.field_names.product_select"),
      status: i18n.t("pages.client_settings.client.field_names.status"),
      type: i18n.t("pages.client_settings.client.field_names.type"),
      category: i18n.t("pages.client_settings.client.field_names.category"),
      contact_supplier: i18n.t("pages.client_settings.client.field_names.contact_supplier"),
      location: i18n.t("pages.client_settings.client.field_names.location"),
      groups: i18n.t("pages.client_settings.client.field_names.groups"),
      brand: i18n.t("pages.client_settings.client.field_names.brand"),
      supplier: i18n.t("pages.client_settings.client.field_names.supplier"),
      shipped_date: i18n.t("pages.client_settings.client.field_names.shipped_date"),
      transfer_to_location: i18n.t("pages.client_settings.client.field_names.transfer_to_location"),
      contact: i18n.t("pages.client_settings.client.field_names.contact"),
      contact_street1: i18n.t("pages.client_settings.client.field_names.contact_street1"),
      contact_city: i18n.t("pages.client_settings.client.field_names.contact_city"),
      contact_country_state: i18n.t("pages.client_settings.client.field_names.contact_country_state"),
      contact_zipcode: i18n.t("pages.client_settings.client.field_names.contact_zipcode"),
      reference: i18n.t("pages.client_settings.client.field_names.reference"),
      currency: i18n.t("pages.client_settings.client.field_names.currency"),
      total_amount: i18n.t("pages.client_settings.client.field_names.total_amount"),
      total_amount_wo_tax: i18n.t("pages.client_settings.client.field_names.total_amount_wo_tax"),
      status_payment: i18n.t("pages.client_settings.client.field_names.status_payment"),
      invoice_date: i18n.t("pages.client_settings.client.field_names.invoice_date"),
      due_date: i18n.t("pages.client_settings.client.field_names.due_date"),
      payment_term: i18n.t("pages.client_settings.client.field_names.payment_term"),
      location_shipping: i18n.t("pages.client_settings.client.field_names.location_shipping"),
      location_billing: i18n.t("pages.client_settings.client.field_names.location_billing"),
      contact_contact_person_first_name: i18n.t("pages.client_settings.client.field_names.contact_contact_person_first_name"),
      contact_contact_person_last_name: i18n.t("pages.client_settings.client.field_names.contact_contact_person_last_name"),
      contact_person_email: i18n.t("pages.client_settings.client.field_names.contact_person_email"),
      item_product_var_id: i18n.t("pages.client_settings.client.field_names.item_product_var_id"),
      credit_id: i18n.t("pages.client_settings.client.field_names.credit_id"),
      stock_rack_name: i18n.t("pages.client_settings.client.field_names.stock_rack_name"),
      stock_rack_id: i18n.t("pages.client_settings.client.field_names.stock_rack_id"),
      created_by: i18n.t("pages.client_settings.client.field_names.created_by"),
      created_at: i18n.t("pages.client_settings.client.field_names.created_at"),
    }
  },
  mutations: {},
  actions: {},
  getters: {
    settings: state => state.settings,

    accounting_settings_billing_address_options: state => state.accounting_settings.billing_address_options,
    accounting_settings_shipping_address_options: state => state.accounting_settings.shipping_address_options,
    accounting_settings_billing_location_options: state => state.accounting_settings.billing_location_options,
    accounting_settings_shipping_location_options: state => state.accounting_settings.shipping_location_options,
    accounting_settings_shipments_options: state => state.accounting_settings.shipments_options,
    accounting_settings_transfers_options: state => state.accounting_settings.transfers_options,
    accounting_settings_proforma_invoice_options: state => state.accounting_settings.proforma_invoice_options,
    accounting_settings_invoice_options: state => state.accounting_settings.invoice_options,
    accounting_settings_create_fulfillment_with_options: state => state.accounting_settings.create_fulfillment_with_options,
    accounting_settings_credits_belong_tos_options: state => state.accounting_settings.credits_belong_tos_options,
    accounting_settings_default_status_options: state => state.accounting_settings.default_status_options,

    marketing_settings_accumulate_amount_type: state => state.marketing_settings.accumulate_amount_type,
    marketing_settings_trigger: state => state.marketing_settings.trigger,
    marketing_settings_apply_order: state => state.marketing_settings.apply_order,
    marketing_settings_apply_on: state => state.marketing_settings.apply_on,
    marketing_settings_amount_type: state => state.marketing_settings.amount_type,
    marketing_settings_repetition: state => state.marketing_settings.repetition,
    inventory_settings_stock_type: state => state.inventory_settings.stock_type,
    inventory_settings_stock_model: state => state.inventory_settings.stock_model,
    inventory_settings_quantity_type: state => state.inventory_settings.quantity_type,
    inventory_settings_inventory_types: state => state.inventory_settings.inventory_types,
    inventory_settings_filter_items_attributes_field_type: state => state.inventory_settings.filter_items.field_type,
    inventory_settings_filter_items_attributes_filter_type: state => state.inventory_settings.filter_items.filter_type,
    inventory_settings_filter_items_attributes_placement: state => state.inventory_settings.filter_items.placement,
    inventory_settings_filter_items_attributes_field_type_measurements: state => state.inventory_settings.filter_items.field_type_measurements,
    inventory_settings_filter_items_attributes_field_type_fields: state => state.inventory_settings.filter_items.field_type_fields,

    inventory_settings_catalog_field_types: state => state.inventory_settings.catalog.type,
    inventory_settings_catalog_field_type_static_names: state => state.inventory_settings.catalog.field_type_static_names,
    inventory_settings_catalog_field_type_measurements: state => state.inventory_settings.catalog.field_type_measurements,
    inventory_settings_catalog_links: state => state.inventory_settings.catalog.link,
    inventory_settings_catalog_link_targets: state => state.inventory_settings.catalog.link_target,
    catalog_filter_settings_mapping: state => state.catalog_filter_settings_mapping,

    logistic_settings_shipment_types: state => state.logistic_settings.shipmentTypes,

    contact_settings_preferred_languages: state => state.contact_settings.preferred_languages,

    client_currency_settings_multi_currency_rate_models: state => state.client.currency_settings.multi_currency_rate_models,
    client_currency_settings_ui_labels_languages: state => state.client.ui_labels.languages,

    filter_field_names: state => state.filter_field_names
  }
};
