import { securedAxios } from '@/utils/securedAxios'
import { get, pickBy } from 'lodash'

const state = () => ({
  taxonData: null,
  previewFile: null,
  bugSubmissionModal: false,
  clientFtpModal: false,
  clientList: false,
  approveJobIds: [],
  approveJobId: null,
  approveType: null,
  agentToken: null,
  agentModal: false,

  // ! For ApprovalModal
  approvalModalJobIndex: 0,
  approvalModalBundleIndex: 0,

  // ! Preview File See Approval Statuses
  isPreviewFileShowInfo: true,
  isPreviewFileApprovalStatusShow: false,
  isPreviewFileTransferStatusShow: false,
})

const getters = {
  previewFile: state => state.previewFile,
  maestroSettings: (state, getters, rootState, rootGetters) => get(rootGetters.activeUser, 'client.maestro_settings', {}),
  ftpInfo: (state, getters) => pickBy(getters.maestroSettings, (value, key) => key.startsWith('ftp_') ||  ['server', 'maestro_number_of_threads'].includes(key)),
  bugSubmissionModal: state => state.bugSubmissionModal,
  clientFtpModal: state => state.clientFtpModal,
  mailSettings: (state, getters) => pickBy(getters.maestroSettings, (value, key) => key.startsWith('mail_')),
  approvalJobIds: state => state.approveJobIds,
  approvalJobId: state => state.approveJobId,
  approveType: state => state.approveType,
  agentToken: state => state.agentToken,
  agentModal: state => state.agentModal,
  approvalModalJobIndex: state => state.approvalModalJobIndex,
  approvalModalBundleIndex: state => state.approvalModalBundleIndex,
  isPreviewFileShowInfo: state => state.isPreviewFileShowInfo,
  isPreviewFileApprovalStatusShow: state => state.isPreviewFileApprovalStatusShow,
  isPreviewFileTransferStatusShow: state => state.isPreviewFileTransferStatusShow,
}

const mutations = {
  SET_TAXON_DATA (state, payload) {
    state.taxonData = payload
  },
  SET_PREVIEW_FILE (state, payload) {
    state.previewFile = payload
  },
  SET_BUG_SUBMISSION_MODAL (state, payload) {
    state.bugSubmissionModal = payload
  },
  SET_CLIENT_FTP_MODAL (state, payload) {
    state.clientFtpModal = payload
  },
  SET_APPROVAL_JOB_IDS (state, payload) {
    state.approveJobIds = payload
  },
  SET_APPROVAL_JOB_ID (state, payload) {
    state.approveJobId = payload
  },
  SET_APPROVAL_JOB_TYPE (state, payload) {
    state.approveType = payload
  },
  SET_AGENT_TOKEN (state, payload) {
    state.agentToken = payload
  },
  SET_AGENT_MODAL (state, payload) {
    state.agentModal = payload
  },
  SET_APPROVAL_MODAL_VALUES(state, payload) {
    const values = { ...payload }
    Object.keys(values).forEach(k => state[k] = values[k])
  },
  SET_PREVIEW_MODAL_VALUES(state, payload) {
    const values = { ...payload }
    Object.keys(values).forEach(k => state[k] = values[k])
  }
}

const actions = {
  getFileFamily ({ commit }, id) {
    securedAxios.get(`/prepress/files/${id}/get_family`)
      .then(response => {
        commit('SET_PREVIEW_FILE', response.data)
      })
  },
  getTokenInfo( { commit }) {
    return securedAxios.get('/prepress/agent_info')
      .then(response => {
        console.log(response.data.token)
        commit('SET_AGENT_TOKEN', response.data?.token)
      })
  },
  resetTokenInfo({ commit }) {
    return securedAxios.post('/prepress/reset_agent_token', {})
      .then(response => {
        commit('SET_AGENT_TOKEN', response.data?.token)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
