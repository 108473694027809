<template>
  <!-- Search -->
  <div>
    <b-form-group :class="{ 'm-0': !label && !addButton }">
      <label v-if="label || addButton" class="d-flex align-items-center justify-content-start">
        <!--        <b-link v-if="addButton" @click="quick_contact_create_modal_show = true"-->
        <!--                class="m-0 mr-1 new_contact_button text-muted">-->
        <!--          <i class="fa fa-plus"></i-->
        <!--          ></b-link>-->
        <span v-if="label">{{ title }}</span>
      </label>
      <div class="contact-search-container flex-gap-5">
        <treeselect
          v-if="newDesign && !disabled"
          valueFormat="object"
          v-model="selected_contact"
          :async="true"
          :load-options="loadOptions"
          :placeholder="placeholder ? placeholder : $t('components.contact_search.pick_a_contact')"
          :auto-load-root-options="false"
          :cacheOptions="false"
          :multiple="multiple"
          :normalizer="normalizer"
          @input="setSearchItem"
          @open="$emit('focus')"
          @deselect="$emit('focusout')"
        />
        <v-select
          v-else-if="!newDesign && !disabled"
          :disabled="disabled"
          :placeholder="placeholder ? placeholder : $t('components.contact_search.pick_a_contact')"
          transition="none"
          label="name"
          :filterable="false"
          :multiple="multiple"
          :taggable="taggable"
          v-model="selected_contact"
          :options="founded_customer_options"
          @search="onSearch"
          @input="setSearchItem($event)"
          @search:focus="$emit('focus')"
          @search:blur="$emit('focusout')"
          :class="{ 'is-invalid-custom': suspendedAccount || validationError }"
        >
          <template slot="no-options">
            <div>{{ $t(`components.contact_search.search_message_${contact_type}`) }}</div>
          </template>
          <!-- <template slot="no-options" v-if="didSearch">{{ $t("general.search_message.no_options") }}</template> -->

          <template slot="option" slot-scope="option">
            <div v-if="!option.suspended" class="d-flex justify-content-between align-items-center">
              <div style="gap:5px;" class="d-flex justify-content-start align-items-center">
                <span class="font-weight-bold">{{ option.auto_id }} </span>
                <span>{{ option.name }} </span>
              </div>
              <span v-if="showAddressInList" style="gap:5px;" class="d-flex justify-content-start align-items-center">
              <span class="font-weight-bold">{{ $t("components.contact_search.addr_shorten") }}: </span>
              <span>{{ option.mainAddress }}</span>
            </span>
            </div>
            <div v-else class="d-flex justify-content-between align-items-center">
            <span class="text-muted">
              <span class="mr-2 font-weight-bold">{{ option.auto_id }} </span>
              <span>{{ option.name }} </span>
            </span>
              <small class="font-weight-bold text-danger">{{ $t("components.contact_search.contact_suspended")
                }}</small>
            </div>
          </template>

          <template slot="selected-option" slot-scope="option">
            <div v-if="!option.suspended" class="d-flex justify-content-start align-items-center">
              <span class="mr-2 font-weight-bold">{{ option.auto_id }} </span>
              <span>{{ option.name }} </span>
            </div>

            <div v-else class="w-100 d-flex justify-content-between align-items-center">
            <span class="text-muted mr-2">
              <span class="mr-2 font-weight-bold">{{ option.auto_id }} </span>
              <span>{{ option.name }} </span>
            </span>
              <small class="font-weight-bold text-danger">{{ $t("components.contact_search.contact_suspended")
                }}</small>
            </div>
          </template>
        </v-select>
        <span v-else class="font-weight-bold">
          <span v-html="selectedContact"></span>
          <small v-if="suspendedAccount"
                 class="font-weight-bold text-danger mr-2">{{ $t("components.contact_search.contact_suspended")
            }}</small>
        </span>

        <b-button
          v-if="addButton && !disabled"
          size="xs"
          variant="light"
          @click="quick_contact_create_modal_show = true"
          class="default add-customer-button">
          <i class="fa fa-plus"></i>
        </b-button>
        <slot name="customButton"></slot>
      </div>
    </b-form-group>
    <QuickContactCreateModal
      :contact_type="contact_type"
      :show="quick_contact_create_modal_show"
      @hideEvent="quick_contact_create_modal_show = false"
      @created-contact="fetchContact($event)"
    />
  </div>
</template>

<script>
import { isArray, isObject } from "lodash";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import _ from "lodash";

export default {
  props: {
    newDesign: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    contact_type: {
      type: String,
      required: true
    },
    // Returning Full Contact Object or Contact's id
    return_full_object: {
      type: Boolean,
      required: false,
      default: true
    },
    return_fields: {
      type: [Array, null],
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    additional_params: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: Boolean,
      required: false,
      default: true
    },
    showAddressInList: {
      type: Boolean,
      required: false,
      default: false
    },
    addButton: {
      type: Boolean,
      required: false,
      default: false
    },
    validationError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    QuickContactCreateModal: () => import("@/components/Form/QuickContactCreateModal")
  },
  data() {
    return {
      didSearch: false,
      founded_customer_options: [],
      contact: null,
      selected_contact: null,
      quick_contact_create_modal_show: false
    };
  },
  methods: {
    loadOptions: _.debounce(function({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        let params = {
          search_text: searchQuery,
          type: this.contact_type
        };

        if (isObject(this.additional_params)) {
          params = {
            ...params,
            ...this.additional_params
          };
        }

        this.founded_customer_options = [];
        this.$store
          .dispatch("fetch", {
            endpoint: this.$store.state.contacts.endpoint + "quick_search",
            params: {
              params
            }
          })
          .then((response) => {
            this.founded_customer_options = response.contacts?.map((c) => {
              return {
                ...c,
                label: c.name_extra || c.name
              };
            });

            callback(null, this.founded_customer_options);
          });
      }
    }),
    /************** Search Methods ********** */
    onSearch(search, loading) {
      // if (search.length < 3) {
      //   this.didSearch = false;
      //   this.founded_customer_options = [];
      // }
      // if (search.length >= 0) {
      this.search(loading, search, this);
      // }
    },
    search: _.debounce((loading, search, vm) => {
      let params = {
        search_text: search,
        type: vm.contact_type
      };

      if (isObject(vm.additional_params)) {
        params = {
          ...params,
          ...vm.additional_params
        };
      }

      vm.founded_customer_options = [];
      vm.$store
        .dispatch("fetch", {
          endpoint: vm.$store.state.contacts.endpoint + "quick_search",
          params: {
            params
          }
        })
        .then((response) => {
          vm.didSearch = true;
          vm.founded_customer_options = response.contacts?.map((c) => {
            let mainAddress = c?.addresses?.find(a => a.primary);
            if (!mainAddress && c.addresses?.length > 0) mainAddress = c.addresses[0];
            mainAddress = mainAddress?.street1;
            return {
              ...c,
              name: c.name_extra || c.name,
              mainAddress
            };
          });
        });
    }, 1000),
    setSearchItem(selected) {
      let returnValue = {};
      if (selected !== null) {
        if (!this.multiple) {
          if (isArray(this.return_fields) && this.return_fields.length > 0) {
            this.return_fields.forEach((field) => {
              if (this.selected_contact.hasOwnProperty(field)) {
                returnValue[field] = this.selected_contact[field];
              }
            });
          } else if (this.return_full_object === true) {
            returnValue = this.selected_contact;
          } else {
            // returnValue = this.newDesign ? this.selected_contact : this.selected_contact?.id;
            returnValue = this.selected_contact?.id;
          }
          // Multiple Contact
        } else {
          if (isArray(this.return_fields) && this.return_fields.length > 0) {
            returnValue = this.selected_contact.map((sc) => {
              let wantedFields = Object.keys(sc).filter((wf) => this.return_fields.indexOf(wf) > -1);
              let tReturnValue = {};
              wantedFields.forEach((key) => {
                if (sc?.[key]) {
                  tReturnValue[key] = sc[key];
                }
              });
              return tReturnValue;
            });
          } else if (this.return_full_object === true) {
            returnValue = this.selected_contact;
          } else {
            returnValue = this.selected_contact?.map((c) => c.id);
          }
        }
      }
      this.$emit("input", selected === null ? null : returnValue);
    },
    fetchContact(contactID) {
      if (!this.multiple) {
        if ((this.value !== "undefined" && this.value !== null && this.value !== undefined && !!Object.keys(this.value).length) || contactID) {
          let params = {};
          if (isObject(this.additional_params)) {
            params = {
              ...this.additional_params
            };
          }

          this.$store
            .dispatch("fetchOne", {
              endpoint: this.$store.state.contacts.endpoint,
              id: contactID || this.value?.id || this.value,
              params: params
            })
            .then((contact_response) => {
              // if (contact_response.status == 200) {
              this.selected_contact = {
                ...contact_response.contact,
                label: `${contact_response.contact.name} (${contact_response.contact?.auto_id || "-"})`
              };
              this.setSearchItem(contact_response.contact);
              // }
            })
            .catch((e) => {
              this.createErrorNotification(e);
            });
        }
      } else {
        if (isArray(this.value)) {
          this.value?.forEach((contact) => {
            this.$store
              .dispatch("fetchOne", {
                endpoint: this.$store.state.contacts.endpoint,
                id: contact
              })
              .then((contact_response) => {
                // if (contact_response.status == 200) {
                this.selected_contact.push({
                  ...contact_response?.contact,
                  label: contact_response?.contact?.name || contact_response?.contact?.company_name
                });
                // this.setSearchItem(contact_response);
                // }
              })
              .catch((e) => {
                this.createErrorNotification(e);
              });
          });
        }
      }
    },
    normalizer(node) {
      return {
        id: node?.id || node.key,
        label: node?.label || node.name,
        children: node?.children || node.subOptions
      };
    }
  },
  mounted() {
    if (this.multiple) {
      this.selected_contact = [];
    }
    this.fetchContact();
  },
  watch: {
    value(value) {
      this.contact = this.value;
      if (this.newDesign && isObject(this.value)) {
        this.selected_contact = this.value;
      }
      if (this.value === null) {
        this.founded_customer_options = [];
        this.selected_contact = null;
      }
    },
    suspendedAccount(val) {
      this.$emit("suspended-account-event", val);
    }
  },
  computed: {
    suspendedAccount() {
      return !this.multiple && isObject(this.selected_contact) && this.selected_contact.suspended;
    },
    selectedContact() {
      if (isArray(this.selected_contact)) {
        return this.selected_contact?.map((c) => `${c?.name} <span class="text-muted">(${c?.auto_id})</span>`)?.join(", ");
      } else {
        return `${this.selected_contact?.name} <span class="text-muted">(${this.selected_contact?.auto_id})</span>`;
      }
    },
    title() {
      return this.$t(`components.contact_search.${this.contact_type}`);
    },
    componentClassess() {
      return {
        "justify-content-between": this.label && this.addButton,
        "justify-content-start": this.label && !this.addButton,
        "justify-content-end": !this.label && this.addButton
      };
    }
  }
};
</script>
<style lang="scss">
.new_contact_button:hover {
  color: #145388 !important;
}

.suspend_item {
  pointer-events: none !important;
  cursor: default !important;
}

.contact-search-container {
  display: flex;
  align-items: center;
  width: 100%;

  .add-customer-button {
    height: 33px;
    border: 1px solid #ddd;
    border-radius: 0 !important;
  }
}

.last-purchase-button {
  height: 33px;
  color: #2a93d5;
  border: 1px solid #ddd;
  border-radius: 0 !important;
  font-weight: bolder !important;
}
</style>
