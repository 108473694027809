import { mapGetters } from "vuex";
import { store } from "../store/index";
import { i18n } from "@/utils/i18n";
import { isNumber, isObject, isArray, get, isString } from "lodash";
import anime from "animejs/lib/anime.es.js";
import moment from "moment-timezone";
import CryptoJS from "crypto-js";
import { templateMap } from "@/constants/config";
import localforage from "localforage";
import { UI } from "@/constants/client/Settings";

const helperMixin = {
  data() {
    return {
      print_body: null,
      isLoading: false,
      saltRounds: 10,
      cryptPassword: "pur350l**//w3ae8",
      show_loading: true,
      monthList: [
        i18n.t("general.months.jan"),
        i18n.t("general.months.feb"),
        i18n.t("general.months.mar"),
        i18n.t("general.months.apr"),
        i18n.t("general.months.may"),
        i18n.t("general.months.june"),
        i18n.t("general.months.july"),
        i18n.t("general.months.aug"),
        i18n.t("general.months.sep"),
        i18n.t("general.months.oct"),
        i18n.t("general.months.nov"),
        i18n.t("general.months.dec")
      ],
      langMap: [
        "af-ZA",
        "am-ET",
        "ar-AE",
        "ar-BH",
        "ar-DZ",
        "ar-EG",
        "ar-IQ",
        "ar-JO",
        "ar-KW",
        "ar-LB",
        "ar-LY",
        "ar-MA",
        "arn-CL",
        "ar-OM",
        "ar-QA",
        "ar-SA",
        "ar-SD",
        "ar-SY",
        "ar-TN",
        "ar-YE",
        "as-IN",
        "az-az",
        "az-Cyrl-AZ",
        "az-Latn-AZ",
        "ba-RU",
        "be-BY",
        "bg-BG",
        "bn-BD",
        "bn-IN",
        "bo-CN",
        "br-FR",
        "bs-Cyrl-BA",
        "bs-Latn-BA",
        "ca-ES",
        "co-FR",
        "cs-CZ",
        "cy-GB",
        "da-DK",
        "de-AT",
        "de-CH",
        "de-DE",
        "de-LI",
        "de-LU",
        "dsb-DE",
        "dv-MV",
        "el-CY",
        "el-GR",
        "en-029",
        "en-AU",
        "en-BZ",
        "en-CA",
        "en-cb",
        "en-GB",
        "en-IE",
        "en-IN",
        "en-JM",
        "en-MT",
        "en-MY",
        "en-NZ",
        "en-PH",
        "en-SG",
        "en-TT",
        "en-US",
        "en-ZA",
        "en-ZW",
        "es-AR",
        "es-BO",
        "es-CL",
        "es-CO",
        "es-CR",
        "es-DO",
        "es-EC",
        "es-ES",
        "es-GT",
        "es-HN",
        "es-MX",
        "es-NI",
        "es-PA",
        "es-PE",
        "es-PR",
        "es-PY",
        "es-SV",
        "es-US",
        "es-UY",
        "es-VE",
        "et-EE",
        "eu-ES",
        "fa-IR",
        "fi-FI",
        "fil-PH",
        "fo-FO",
        "fr-BE",
        "fr-CA",
        "fr-CH",
        "fr-FR",
        "fr-LU",
        "fr-MC",
        "fy-NL",
        "ga-IE",
        "gd-GB",
        "gd-ie",
        "gl-ES",
        "gsw-FR",
        "gu-IN",
        "ha-Latn-NG",
        "he-IL",
        "hi-IN",
        "hr-BA",
        "hr-HR",
        "hsb-DE",
        "hu-HU",
        "hy-AM",
        "id-ID",
        "ig-NG",
        "ii-CN",
        "in-ID",
        "is-IS",
        "it-CH",
        "it-IT",
        "iu-Cans-CA",
        "iu-Latn-CA",
        "iw-IL",
        "ja-JP",
        "ka-GE",
        "kk-KZ",
        "kl-GL",
        "km-KH",
        "kn-IN",
        "kok-IN",
        "ko-KR",
        "ky-KG",
        "lb-LU",
        "lo-LA",
        "lt-LT",
        "lv-LV",
        "mi-NZ",
        "mk-MK",
        "ml-IN",
        "mn-MN",
        "mn-Mong-CN",
        "moh-CA",
        "mr-IN",
        "ms-BN",
        "ms-MY",
        "mt-MT",
        "nb-NO",
        "ne-NP",
        "nl-BE",
        "nl-NL",
        "nn-NO",
        "no-no",
        "nso-ZA",
        "oc-FR",
        "or-IN",
        "pa-IN",
        "pl-PL",
        "prs-AF",
        "ps-AF",
        "pt-BR",
        "pt-PT",
        "qut-GT",
        "quz-BO",
        "quz-EC",
        "quz-PE",
        "rm-CH",
        "ro-mo",
        "ro-RO",
        "ru-mo",
        "ru-RU",
        "rw-RW",
        "sah-RU",
        "sa-IN",
        "se-FI",
        "se-NO",
        "se-SE",
        "si-LK",
        "sk-SK",
        "sl-SI",
        "sma-NO",
        "sma-SE",
        "smj-NO",
        "smj-SE",
        "smn-FI",
        "sms-FI",
        "sq-AL",
        "sr-BA",
        "sr-CS",
        "sr-Cyrl-BA",
        "sr-Cyrl-CS",
        "sr-Cyrl-ME",
        "sr-Cyrl-RS",
        "sr-Latn-BA",
        "sr-Latn-CS",
        "sr-Latn-ME",
        "sr-Latn-RS",
        "sr-ME",
        "sr-RS",
        "sr-sp",
        "sv-FI",
        "sv-SE",
        "sw-KE",
        "syr-SY",
        "ta-IN",
        "te-IN",
        "tg-Cyrl-TJ",
        "th-TH",
        "tk-TM",
        "tn-ZA",
        "tr-TR",
        "tt-RU",
        "tzm-Latn-DZ",
        "ug-CN",
        "uk-UA",
        "ur-PK",
        "uz-Cyrl-UZ",
        "uz-Latn-UZ",
        "uz-uz",
        "vi-VN",
        "wo-SN",
        "xh-ZA",
        "yo-NG",
        "zh-CN",
        "zh-HK",
        "zh-MO",
        "zh-SG",
        "zh-TW",
        "zu-ZA"
      ]
    };
  },
  methods: {
    hasObjectKey(obj) {
      return !!Object.keys(obj)?.length;
    },
    checkTemplateValueIsArray(arr) {
      return isArray(arr);
    },
    validMongoId(id) {
      return id.match(/^[0-9a-fA-F]{24}$/);
    },
    reportDateFormat(date) {
      if (!date) return "";
      return new Date(date)?.toISOString()?.split("T")[0];
    },
    showLocaleDate(date){
      if(!date) return "-"
      return new Date(date)?.toLocaleString(navigator.language || "en-CA")
    },
    showDateFormat(date, time, textFormat, disableYearInfo, htmlOutputForTime) {
      let months = this.monthList;
      if (date !== "" && date != null) {
        let new_date = new Date(date);
        let day = new_date.getDate() <= 9 ? "0" + new_date.getDate() : new_date.getDate();
        let month = new_date.getMonth() + 1 <= 9 ? "0" + (new_date.getMonth() + 1) : new_date.getMonth() + 1;
        if (time !== undefined && time !== null) {
          let new_time = date.split("T")[1].split(":");
          if (textFormat === true) {
            if (disableYearInfo === true) {
              if (htmlOutputForTime === true) {
                return `${day} ${months[Number(month) - 1]} <small class="text-muted">${new_time[0]}:${new_time[1]}</small>`;
              } else {
                return `${day} ${months[Number(month) - 1]} ${new_time[0]}:${new_time[1]}`;
              }
            }
            if (htmlOutputForTime === true) {
              return `${day} ${months[Number(month) - 1]} <small class="text-muted">${new_date.getFullYear()} ${new_time[0]}:${new_time[1]}</small>`;
            } else {
              return `${day} ${months[Number(month) - 1]} ${new_date.getFullYear()} ${new_time[0]}:${new_time[1]}`;
            }
          } else {
            if (htmlOutputForTime === true) {
              return `${day}.${month}.${new_date.getFullYear()} <small class="text-muted">${new_time[0]}:${new_time[1]}</small>`;
            } else {
              return `${day}.${month}.${new_date.getFullYear()} ${new_time[0]}:${new_time[1]}`;
            }
          }
        }

        if (textFormat === true) {
          if (disableYearInfo === true) {
            return `${day} ${months[Number(month) - 1]}`;
          }
          return `${day} ${months[Number(month) - 1]} ${new_date.getFullYear()}`;
        } else {
          return `${day}.${month}.${new_date.getFullYear()}`;
        }
      } else {
        return "-";
      }
    },
    saveDate(date, time) {
      if (date !== null && date !== "") {
        date = new Date(date);
        // date.setHours(date.getHours() + 3);
        date = date
          .toString()
          .split(" ")
          .slice(0, 4)
          .join(" ");
        if (time !== undefined && isObject(time)) {
          if (time.mm == "") {
            time.mm = "00";
          }
          if (time.HH == "") {
            time.HH = "00";
          }
          date = `${date} ${time.HH}:${time.mm}:00`;
        } else {
          date = `${date} 00:00:00`;
        }
        return date;
      } else {
        return "";
      }
    },
    newSaveDateTime(date) {
      return this.saveDate(date, { HH: moment(date).format('HH'), mm: moment(date).format('mm') })
    },
    truncateDecimals(number, digits) {
      console.log(number, digits)
      const sign = number < 0 ? -1 : 1;
      const absoluteNumber = Math.abs(number);
      const multiplier = Math.pow(10, digits);

      const truncatedNumber = Math.floor(absoluteNumber * multiplier) / multiplier;

      console.log(truncatedNumber, sign, absoluteNumber, multiplier)

      return truncatedNumber * sign;
    },
    //! @rounding : Boolean
    regionalNumber(val) {
      if (val) {
        return parseFloat(val.toString().replace(/,/, "."));
      }
      return val;
    },
    evaluate(str) {
      return Function(`return (${str})`)();
    },
    toFixed(value) {
      return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    },
    _intlCurrencyFormat(number, currency, decimalPlace = 2) {
      return new Intl.NumberFormat(navigator.language || "en-CA", {
        style: "currency",
        currency: currency || this.primaryCurrency,
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: decimalPlace,
        minimumFractionDigits: decimalPlace,
        // roundingMode: 'halfTrunc',
      }).format(number);
    },
    _intlNumberFormat(number, maximumFractionDigits) {
      return new Intl.NumberFormat(navigator.language || "en-CA", {
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionDigits || 3
      }).format(
        number
      );
    },
    _intlPercentFormat(number, maximumFractionDigits) {
      return new Intl.NumberFormat(navigator.language, {
        style: "unit",
        unit: "percent",
        maximumFractionDigits: maximumFractionDigits || 2
      }).format(number);
    },
    _intlDateFormat(date, dateStyle = "short", timeStyle) {
      if (!date) return "-";
      const dateOptions = { dateStyle };
      if (timeStyle) dateOptions.timeStyle = "short";
      return new Intl.DateTimeFormat(navigator.language, dateOptions).format(new Date(date));
    },
    excludePick(object, exludes) {
      return Object?.keys(object)?.reduce((obj, key) => {
        if (!exludes.includes(key)) obj[key] = object[key];
        return obj;
      }, {});
    },
    numberFormat(num, decimalPlace) {
      if (!isNaN(num) && num !== null) {
        let str = num.toString();
        if (str.length < 4) {
          if (typeof num === "string") {
            num = Number(num);
          }
          return num.toFixed(decimalPlace || 2);
        } else {
          if (typeof num === "string") {
            num = Number(num);
          }
          return num.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: decimalPlace || 2
          });
        }
      } else {
        return num;
      }
    },
    roundNumberFormat(num) {
      if (!isNaN(num) && num !== null && isNumber(num)) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
      } else {
        return num;
      }
    },
    currencyFormat(num, decimalPlace) {
      if (typeof num === "string") {
        num = Number(num);
      }
      return num.toLocaleString(undefined, {
        currency: "CAD",
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: decimalPlace || 2
      });
    },
    showAddressLine(address) {
      if (!!Object.keys(address).length) {
        let addressLine = "";

        if (address.street1 !== "" && address.street1 !== undefined && address.street1 !== null) {
          addressLine = addressLine + address.street1 + ", ";
        }

        if (address.street2 !== "" && address.street2 !== undefined && address.street2 !== null) {
          addressLine = addressLine + address.street2 + ", ";
        }

        if (isString(address.country) && address.country?.length >= 2) {
          const country = this.countryList.find(s => s.code === address.country);
          const state = country?.states?.find(s => s._id === address.state);

          const stateName = state?.name || address?.state // ?.charAt(0).toUpperCase() + address?.state?.slice(1);
          addressLine = `${addressLine} ${stateName}, <strong>${country?.name || address?.country}</strong>`;
        }
        return addressLine;
      } else {
        return this.$t("alert-messages.no-contact-address");
      }
    },
    // Sorting Order by ASC
    sortByOrderASC(a, b) {
      let orderA = parseInt(a.order);
      let orderB = parseInt(b.order);

      let comparison = 0;
      if (orderA > orderB) {
        comparison = 1;
      } else if (orderA < orderB) {
        comparison = -1;
      } else if (a.order === null) {
        comparison = 1;
      } else if (b.order === null) {
        comparison = -1;
      }
      return comparison;
    },
    // Sorting Order by DESC
    sortByOrderDESC(a, b) {
      let orderA = a.order;
      let orderB = b.order;

      let comparison = 0;
      if (orderA > orderB) {
        comparison = -1;
      } else if (orderA < orderB) {
        comparison = 1;
      }
      return comparison;
    },
    // Sorting Start Date by ASC
    sortByDateASC(a, b) {
      let orderA = new Date(a.start_date);
      let orderB = new Date(b.start_date);

      let comparison = 0;
      if (orderA > orderB) {
        comparison = 1;
      } else if (orderA < orderB) {
        comparison = -1;
      }
      return comparison;
    },
    // Sorting Start Date by ASC
    sortByCreatedAtASC(a, b) {
      let orderA = new Date(a.created_at);
      let orderB = new Date(b.created_at);

      let comparison = 0;
      if (orderA > orderB) {
        comparison = 1;
      } else if (orderA < orderB) {
        comparison = -1;
      }
      return comparison;
    },
    sortByCreatedAtDESC(a, b) {
      let orderA = new Date(a.created_at);
      let orderB = new Date(b.created_at);

      let comparison = 0;
      if (orderA < orderB) {
        comparison = 1;
      } else if (orderA > orderB) {
        comparison = -1;
      }
      return comparison;
    },
    sortByAmountDESC(a, b) {
      let amountA = a.amount;
      let amountB = b.amount;

      let comparison = 0;
      if (amountA > amountB) {
        comparison = -1;
      } else if (amountA < amountB) {
        comparison = 1;
      }
      return comparison;
    },
    /**
     * @description
     * Takes an Array<V>, and a grouping function,
     * and returns a Map of the array grouped by the grouping function.
     *
     * @param list An array of type V.
     * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
     *                  K is generally intended to be a property key of V.
     *
     * @returns Map of the array grouped by the grouping function.
     */
    //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    //    const map = new Map<K, Array<V>>();
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    getTaxonKey(module_name) {
      let index = this.taxonTypes.findIndex(taxon => taxon.module == module_name);
      if (index > -1) {
        return this.taxonTypes[index].name;
      } else {
        return "";
      }
    },
    fetchTaxons(taxon_key, extendParams) {
      // Fetching Taxons for Accounting Payment Term and Methods
      let params = { type: this.getTaxonKey(taxon_key) };
      if (isObject(extendParams)) {
        params = {
          ...params,
          ...extendParams
        };
      }
      return this.$store
        .dispatch("fetch", {
          endpoint: this.$store.state.taxons.endpoint + "quick_search",
          params: {
            params
          }
        })
        .then(taxons => {
          return taxons[this.$store.state.taxons.fetchKey] || [];
        });
    },
    validNumber(val) {
      if (["", null, undefined].indexOf(val) == -1) {
        val = val.toString();
        let letters = /^[0-9.]+$/;
        if (val.match(letters)) {
          return true;
        }
      }
      return false;
    },
    scroll(arg) {
      let el = document.querySelector(arg.to),
        offset = parseInt(arg.offset) || 0,
        duration = arg.duration || 1000,
        easing = arg.easing || "easeOutExpo",
        callback = arg.callback || null;
      let scrollTop = el.offsetTop - offset;
      if (arg.getBoundingClientRect) {
        const customOffset = el.getBoundingClientRect();
        scrollTop = customOffset.top - customOffset.height;
      }
      if (el) {
        anime({
          targets: ["html", "body"],
          scrollTop,
          duration: duration,
          easing: easing,
          complete: callback
        }).finished.then(() => {
          // bus.$emit("scroll:finished", true);
        });
      }
    },
    // object_id, template_id, template_for
    renderTemplate(object_id, template_id, template_for, additional_params) {
      this.isLoading = true;
      let templateParams = {
        for: template_for,
        object_id: object_id
      };

      if (isObject(additional_params)) {
        templateParams = {
          ...templateParams,
          ...additional_params
        };
      }
      this.$securedAxios
        .get(`${this.$store.state.templates.endpoint}${template_id}/viewer`, {
          params: templateParams
        })
        .then(rendered_template => {
          this.print_body = rendered_template.data.body;
          // this.isLoading = true;
          setTimeout(() => {
            // this.isLoading = false;
            // /#/hive/quotes/63d7da60e256650001709d62
            this.print_body = rendered_template.data.body;
            this.createSuccessNotification("print");
            // this.style.visibility="hidden";
            // let win = window.open("", "Preview", "width=1920,height=1080");
            // const headTemplate = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge"><title>Document</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"><link rel="stylesheet" href="https://www.videosinif.com/puresol/style.css"></head><body><div class="container"><a href="javascript:window.print()" class="printBtn">Print</a>`;
            // const body = rendered_template.data.body.replace(`<div class="footer"></div>`, "");
            // const footerTemplate = `</div> <script type="text/javascript"> window.onload = function() { window.print(); }</script></body></html>`;
            // win.document.write(`${headTemplate}${body}${footerTemplate}`);

            this.$htmlToPaper("printMe", false, () => {
              // this.print_body = null;
              this.isLoading = false;
            });
          }, 1500);
          // return rendered_template;
        })
        .catch(e => {
          this.createErrorNotification("print", e?.response?.data?.message);
          this.isLoading = false;
        });
    },
    getDateFrom(date, range = 30) {
      let referenceDate = new Date().getTime();
      if (date) referenceDate = new Date(date).getTime();
      return new Date(referenceDate - (60 * 60 * 24 * range * 1000));
    },
    newDate(date, withoutFormat) {
      // This is for DatePicker Disabled Dates To...
      if (withoutFormat) {
        return new Date(
          moment(new Date())
            .tz("America/New_York")
            .format("YYYY"),
          moment(new Date())
            .tz("America/New_York")
            .format("M") - 1,
          moment(new Date())
            .tz("America/New_York")
            .format("D")
        );
      }

      if (date) {
        if (date.indexOf(".") > -1 || date.endsWith("Z")) {
          return date.split(".")[0];
        }
        return date;
      }

      return moment(new Date())
        .tz("America/New_York")
        .format("YYYY-MM-DDTHH:mm:ss"); // EST
    },
    cryptHash(toHash) {
      return CryptoJS.AES.encrypt(JSON.stringify(toHash), this.cryptPassword).toString();
    },
    deCryptHash(fromHash) {
      if (localStorage.getItem(fromHash)) {
        return JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem(fromHash).toString(), this.cryptPassword).toString(CryptoJS.enc.Utf8));
      } else {
        return null;
      }
    },
    copy(object) {
      return JSON.parse(JSON.stringify(object));
    },
    createSuccessNotification(forWhat, message, duration) {
      forWhat = forWhat || "create";
      this.$notify("success filled", this.$t("notification.title.success"), message || this.$t(`notification.message.${forWhat}.success`), {
        duration: duration || this.$store.state.notificationConfig.success_duration,
        permanent: this.$store.state.notificationConfig.permanent
      });
    },
    createErrorNotification(forWhat, message, duration) {
      forWhat = forWhat || "create";
      this.$notify("error filled", this.$t("notification.title.error"), message || this.$t(`notification.message.${forWhat}.error`), {
        duration: duration || this.$store.state.notificationConfig.error_duration,
        permanent: this.$store.state.notificationConfig.permanent
      });
    },
    getTemplateKey(key) {
      return templateMap[key];
    },
    goToRoutePage(url) {
      this.$router.push(url);
      setTimeout(() => {
        location.reload();
      }, 300);
    },
    setToClientDB(key, value) {
      return localforage.setItem(key, value);
    },
    getFromClientDB(key) {
      return localforage.getItem(key);
    },
    removeClientDB(key) {
      return localforage.removeItem(key);
    },
    getCryptedData(key) {
      return localforage.getItem(key).then(items => {
        // if (items) {
        return JSON.parse(CryptoJS.AES.decrypt(items?.toString(), this.cryptPassword).toString(CryptoJS.enc.Utf8));
        // }
      });
    },
    createDataTree(dataset) {
      const hashTable = Object.create(null);
      dataset.forEach(aData => hashTable[aData.id] = { ...aData });
      const dataTree = [];
      dataset.forEach(aData => {

        if (aData.parent_taxon) {
          if(!hashTable[aData.parent_taxon]?.children){
            hashTable[aData.parent_taxon].children = [];
          }
          hashTable[aData.parent_taxon].children.push(hashTable[aData.id]);
        }
        else dataTree.push(hashTable[aData.id]);
      });
      return dataTree;
    },
    prepareTreeData(optionList, parentField) {
      optionList?.forEach(item => {
        item.children = optionList?.filter(c => c?.[parentField] === item?.id) || [];
        // optionList = optionList.filter(c => !item.children?.find(i => i.id === c?.id));
        if (item.children?.length === 0) delete item.children;
        if (isArray(item?.children) && item?.children?.length > 0) this.prepareTreeData(item.children);
      });
      return optionList;
    },
    download(file_url, file_name, mimeType, loadingState) {
      this.$emit("loading-event", true);
      if (loadingState) {
        this[loadingState] = true;
      }
      file_name = file_name || file_url.split("?")[0]?.split("/").slice(-1);

      const imageTypes = ["jpg", "jpeg", "png", "tiff", "tif", "bmp", "gif"];
      mimeType = mimeType || file_url.split("?")[0]?.split(".")?.slice(-1)[0];
      if (imageTypes.includes(mimeType)) mimeType = `image/${mimeType}`;

      this.$axios
        .get(`${file_url}`, { responseType: "blob" })
        .then(async response => {
          const blob = await new Blob([response.data], { type: mimeType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file_name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error)
        .finally(() => {
          this.$emit("loading-event", false);
          if (loadingState) {
            this[loadingState] = false;
          }
        });
    },
    seti18nConfiguration() {
      const savedDocs = this.activeUser?.i18n_docs?.find(d => d.language === this.$i18n.locale && d?.doc?.length > 10);
      if (savedDocs) {
        this.$i18n.mergeLocaleMessage(this.$i18n.locale, JSON.parse(savedDocs?.doc));
      }
    },
    setPageTab({ module, id, name }) {
      if (name && id) this.$title = `${module} | ${id} - ${name}`;
      else if (name && !id) this.$title = `${module} | ${name}`;
      else if (!name && id) this.$title = `${module} | ${id}`;
    },
    async asyncTimeout(callback, ms) {
      return new Promise(resolve => {
        setTimeout(() => {
          callback();
          resolve();
        }, ms);
      });
    },
    getProp (item, path, defaultValue) {
      return get(item, path, defaultValue)
    },
    localizedDate(date, options) {
      if(!date) return ''

      const dateObj = new Date(date)
      if(!dateObj) return ''

      const locale = navigator.language || options?.language || 'en-US'

      return new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
        timeStyle: 'short',
        ...options
      }).format(dateObj)
    },
    isEventInElement(event, element)   {
      let rect = element.getBoundingClientRect();

      let x = event.clientX;
      if (x < rect.left || x >= rect.right) return false;
      let y = event.clientY;
      return !(y < rect.top || y >= rect.bottom);
    },
    byteText(bytes, decimals = 2){
      if (!+bytes) return '0 Bytes'

      const k = 1000
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    boxToText(box) {
      const [bottomLeftX = 0, bottomLeftY = 0, topRightX = 0, topRightY = 0] = box || []
      const width = +(((topRightX - bottomLeftX) / 72).toFixed(2))
      const height = +(((topRightY - bottomLeftY) / 72).toFixed(2))
      return `${width} x ${height} in`
    }
  },
  filters: {
    locationName(id) {
      let location_index = store.state.locations.locations.findIndex(l => l.id == id);
      if (location_index > -1) {
        return store.state.locations.locations[location_index].name;
      } else {
        return i18n.t("general.hasnt_defined");
      }
    }
  },
  computed: {
    ...mapGetters({
      taxonTypes: "taxons/taxonTypes",
      stateList: "countries/stateList",
      countryList: "countries/countryList",
      clientName: "clientName"
    }),
    primaryCurrency() {
      const clientCurrencies = this?.activeUser?.client?.currencies || [];
      let numberFormat = null;
      let primaryCurrency = clientCurrencies?.find(c => c.primary);
      if (!primaryCurrency && currencies?.length > 0) primaryCurrency = clientCurrencies[0];
      if (primaryCurrency?.code?.includes("USD")) numberFormat = "en-US";
      else if (primaryCurrency?.code?.includes("CAD")) numberFormat = "fr-CA";
      else numberFormat = navigator.language;

      // return {
      //   numberFormat,
      //   currency: primaryCurrency?.code || null
      // };
      return primaryCurrency?.code || "CAD";
    },
    isMobile() {
      const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

      return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    clientUISettings() {
      return UI[this.activeUser?.client?.name] || {};
    },
    isPrepressClient(){
      return this?.activeUser?.client?.medium === "prepress"
    }
  }
};

export default helperMixin;
