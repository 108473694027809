import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/hive/jobs/",
    template_endpoint: "/hive/jobs/templates/",
    key: "job",
    fetchKey: "jobs",
    statusList: [
      {
        name: "pending",
        text: i18n.t("pages.hive.jobs.statusList.pending"),
        color: "warning"
      },
      {
        name: "active",
        text: i18n.t("pages.hive.jobs.statusList.active"),
        color: "primary"
      },
      {
        name: "completed",
        text: i18n.t("pages.hive.jobs.statusList.completed"),
        color: "success"
      },
      {
        name: "deleted",
        text: i18n.t("pages.hive.jobs.statusList.deleted"),
        color: "danger"
      }
    ],
    typeList: [
      {
        name: "printing",
        text: i18n.t("pages.hive.jobs.typeList.printing")
      },
      {
        name: "installation",
        text: i18n.t("pages.hive.jobs.typeList.installation")
      }
    ],
    belongsToList: [
      {
        name: "project",
        text: i18n.t("pages.hive.jobs.belongsToList.project")
      },
      {
        name: "work_order",
        text: i18n.t("pages.hive.jobs.belongsToList.work_order")
      },
      {
        name: "template",
        text: i18n.t("pages.hive.jobs.belongsToList.template")
      }
    ],
    stepList: [
      { name: "material", text: i18n.t("pages.hive.jobs.stepList.materiel") },
      { name: "cutting", text: i18n.t("pages.hive.jobs.stepList.coupe") },
      { name: "lamination", text: i18n.t("pages.hive.jobs.stepList.laminage") },
      { name: "tuilage", text: i18n.t("pages.hive.jobs.stepList.tuilage") },
      { name: "quincaillerie", text: i18n.t("pages.hive.jobs.stepList.quincaillerie") },
      { name: "montage", text: i18n.t("pages.hive.jobs.stepList.montage") },
      { name: "finition_couture", text: i18n.t("pages.hive.jobs.stepList.finition_couture") },
      { name: "peinture", text: i18n.t("pages.hive.jobs.stepList.peinture") },
      { name: "bleed_chevauchement", text: i18n.t("pages.hive.jobs.stepList.bleed_chevauchement") },
      { name: "decoupe_vinyle", text: i18n.t("pages.hive.jobs.stepList.decoupe_vinyle") },
      { name: "emballage_kitting", text: i18n.t("pages.hive.jobs.stepList.emballage_kitting") },
      { name: "decoupe_numerique_signaletique", text: i18n.t("pages.hive.jobs.stepList.decoupe_numerique_signaletique") }
    ],
    lamcomFileAssignStatusList: [
      {
        name: "pending",
        text: i18n.t("pages.hive.jobs.lamcomFileAssignStatusList.pending"),
        color: "text-danger"
      },
      {
        name: "partial",
        text: i18n.t("pages.hive.jobs.lamcomFileAssignStatusList.partial"),
        color: "text-warning"
      },
      {
        name: "completed",
        text: i18n.t("pages.hive.jobs.lamcomFileAssignStatusList.completed"),
        color: "text-success"
      },
      {
        name: "active",
        text: i18n.t("pages.hive.jobs.lamcomFileAssignStatusList.active"),
        color: "text-primary"
      }
    ],

    permissionKey: ""
  },
  mutations: {},
  actions: {},
  getters: {
    statusList: state => state.statusList,
    typeList: state => state.typeList,
    belongsToList: state => state.belongsToList,
    stepList: state => state.stepList,
    lamcomFileAssignStatusList: state => state.lamcomFileAssignStatusList
  }
};
