import Vue from "vue";
import router from "./router";

import { store } from "./store";
import { i18n } from "./utils/i18n";
import VueSweetalert2 from "vue-sweetalert2";
import JsonExcel from "vue-json-excel";
import VueExcelXlsx from "vue-excel-xlsx";
import axios from "axios";
import { securedAxios } from "@/utils/securedAxios";
import { reportAxios } from "@/utils/reportAxios";
import { tpaSecuredAxios } from "@/utils/tpaSecuredAxios";

import alertMixin from "@/mixins/alertMixin";
import permissionMixin from "@/mixins/permissionMixin";
import _permissionMixin from "@/mixins/_permissionMixin";
import helperMixin from "@/mixins/helperMixin";
import computedMixins from "@/mixins/computedMixins";
import uiSettingsMixin from "@/mixins/uiSettingsMixin";

import "./assets/css/vendor/dropzone.min.css";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/sass/themes/piaf.light.blue.scss";
// import "./assets/css/sass/themes/piaf.dark.orange.scss";
import "./assets/css/custom/puresol.css";
import "./assets/css/sass/custom-styles.scss";
import 'animate.css';

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Notifications from "@/components/Common/Notification";
import Breadcrumb from "@/components/Common/Breadcrumb";
import RefreshButton from "@/components/Common/RefreshButton";
import Colxx from "@/components/Common/Colxx";
import vuePerfectScrollbar from "vue-perfect-scrollbar";

import VueQuagga from "vue-quaggajs";

import PortalVue from "portal-vue";
Vue.use(PortalVue);
Vue.use(VueQuagga);
Vue.use(VueExcelXlsx);

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.use(LottieVuePlayer);

import LazyComponent from "v-lazy-component";

Vue.use(LazyComponent);

/*********** Custom Components *******************/
import ButtonToolbar from "@/components/Form/ButtonToolbar";
import SubmitButton from "@/components/Form/Permission/SubmitButton";
import NewButton from "@/components/Form/Permission/NewButton";
import CustomField from "@/components/Form/CustomField";
import Measurement from "@/components/Form/Measurement";
import Calculator from "@/components/Form/Calculator";
import TaxonSearch from "@/components/Form/TaxonSearch";
import ContactSearch from "@/components/Form/ContactSearch";
import PrintButton from "@/components/Form/PrintButton";
import SubTitle from "@/components/Clients/atoms/sub_title_a";
import LabelledContainer from "@/components/Clients/atoms/LabelledContainer";
import PNumericInput from "@/components/Form/PNumericInput.vue";
import PFormBuilder from "@/components/PFormBuilder/index.vue";

import vSelect from "vue-select";
import Switches from "vue-switches";
import VueToggles from "vue-toggles";
import VueTimepicker from "vue2-timepicker";

import TreeSelect from "@riophae/vue-treeselect";
import PTreeselect from "@/components/Form/PTreeselect";

Vue.component("treeselect", TreeSelect);
Vue.component("PTreeselect", PTreeselect);
Vue.component("PFormBuilder", PFormBuilder);
Vue.component("PNumericInput", PNumericInput);
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import TreeView from "vue-json-tree-view";

Vue.use(TreeView);

/****** VueHTMLToPaper - using for Print Buttons  **********/
import VueHtmlToPaper from "vue-html-print";

const printing_options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  // styles: [
  //   "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css",
  //   "https://blankspace-upload-service-files.s3.eu-central-1.amazonaws.com/oir1lqh.css"
  // ]
  // "https://tasarimlife.org/css/style.css",
  // styles: ["https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css","https://www.videosinif.com/puresol/style.css"]
  // styles: ["https://www.videosinif.com/puresol/style.css"]
  styles: []
};
const isLamcomClient = window.location.href.includes("lamcom.") || window.location.href.includes("localhost");

if (isLamcomClient) {
  printing_options.styles.push("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css");
}

Vue.use(VueHtmlToPaper, printing_options);
//************************************************************* */

import VuePageTitle from "vue-page-title";

Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: " | PureSOL",
  router
});

import VueCurrencyInput from "vue-weblineindia-currency-input/src";

Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: navigator?.language || "en-CA",
    currency: null,
    valueRange: {
      min: 0,
      max: undefined
    },
    precision: 2,
    allowNegative: false
  }
});


import { Drag, Drop } from "vue-drag-drop";

Vue.component("drag", Drag);
Vue.component("drop", Drop);

import draggable from 'vuedraggable'

Vue.component('draggable', draggable)

import VueGridLayout from "vue-grid-layout";

Vue.component("GridLayout", VueGridLayout.GridLayout);
Vue.component("GridItem", VueGridLayout.GridItem);

/*********** #Custom Components *******************/

/* OPTINAL -begin */
import contentmenu from "v-contextmenu";
import VCalendar from "v-calendar";
// import "v-calendar/lib/v-calendar.min.css";

import DatePicker from "@/components/Form/DatePicker";
import DateTimePicker from '@/components/Form/DateTimePicker'

Vue.component("p-date-picker", DatePicker);
Vue.component("p-date-time-picker", DateTimePicker);
Vue.component("p-switch", PSwitch);

import CardLoading from "@/components/Common/CardLoading";

Vue.component("card-loading", CardLoading);

/* OPTINAL -end */
import App from "./App";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

const options = {
  confirmButtonColor: "#f18024",
  cancelButtonColor: "#dc3545"
};

Vue.use(VueSweetalert2, options);

Vue.component("timePicker", VueTimepicker);
import "vue2-timepicker/dist/VueTimepicker.css";

Vue.component("downloadExcel", JsonExcel);
Vue.component("v-select", vSelect);
Vue.component("switches", Switches);
Vue.component("vue-toggle", VueToggles);
Vue.component("taxon-search", TaxonSearch);
Vue.component("contact-search", ContactSearch);
Vue.component("print-button", PrintButton);
Vue.component("SubTitle", SubTitle);
Vue.component("LabelledContainer", LabelledContainer);

Vue.use(Notifications);
Vue.component("piaf-breadcrumb", Breadcrumb);
Vue.component("b-refresh-button", RefreshButton);
Vue.component("b-colxx", Colxx);
Vue.component("vue-perfect-scrollbar", vuePerfectScrollbar);
Vue.component("ButtonToolbar", ButtonToolbar);
Vue.component("SubmitButton", SubmitButton);
Vue.component("NewButton", NewButton);
Vue.component("CustomField", CustomField);
Vue.component("Measurement", Measurement);
Vue.component("Calculator", Calculator);

/**** Tree View Component */

// Vue editable grid component and styles
import PSwitch from "@/components/Form/PSwitch";

//** Job Management Components Begin
const ListPageFilter = () => import('@/components/ui/ListPageFilter')
Vue.component('ListPageFilter', ListPageFilter)

const JobEditor = () => import('@/components/Form/JobEditor')
Vue.component('JobEditor', JobEditor)

const VueNumericInput = () => import('@/components/Form/NumericInput')
Vue.component('VueNumericInput', VueNumericInput)

const NewRangeInput = () => import('@/components/Form/NewRangeInput')
Vue.component('NewRangeInput', NewRangeInput)

const EntitySearch = () => import('@/components/Form/EntitySearch')
Vue.component('EntitySearch', EntitySearch)

const FLabelInput = () => import('@/components/Form/FLabelInput')
Vue.component('FLabelInput', FLabelInput)

const FLabelTextarea = () => import('@/components/Form/FLabelTextarea')
Vue.component('FLabelTextarea', FLabelTextarea)

const FLabelDatePicker = () => import('@/components/Form/FLabelDatePicker')
Vue.component('FLabelDatePicker', FLabelDatePicker)

const FLabelDateRangeInput = () => import('@/components/Form/FLabelDateRangeInput')
Vue.component('FLabelDateRangeInput', FLabelDateRangeInput)

const FLabelTreeselect = () => import('@/components/Form/FLabelTreeselect')
Vue.component('FLabelTreeselect', FLabelTreeselect)

const DimensionControl = () => import('@/components/Form/DimensionControl')
Vue.component('DimensionControl', DimensionControl)

const AppSnackbar = () => import('@/components/ui/AppSnackbar')
Vue.component('AppSnackbar', AppSnackbar)

const PTable = () => import('@/components/ui/PTable')
Vue.component('PTable', PTable)

const FileBundler = () => import('@/components/ui/FileBundler')
Vue.component('FileBundler', FileBundler)

const LongText = () => import('@/components/ui/LongText')
Vue.component('LongText', LongText)
//** Job Management Components End

/* OPTINAL -begin */
Vue.use(require("vue-shortkey"));
Vue.use(contentmenu);
Vue.use(VCalendar, {
  firstDayOfWeek: 2, // ...other defaults,
  formats: {
    title: "MMM YY",
    weekdays: "WW",
    navMonths: "MMMM",
    input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
    dayPopover: "L"
  },
  datePickerShowDayPopover: true,
  popoverExpanded: true,
  popoverDirection: "bottom"
});

/* OPTINAL -end  */

/************************ Custom Mixins ****************************/
// alertMixin
Vue.mixin(alertMixin);
Vue.mixin(permissionMixin);
Vue.mixin(_permissionMixin);
Vue.mixin(helperMixin);
Vue.mixin(computedMixins);
Vue.mixin(uiSettingsMixin);
Vue.prototype.$log = process.env.NODE_ENV === "development" ? console.log : () => {
};
Vue.prototype.$axios = axios;
Vue.prototype.$securedAxios = securedAxios;
Vue.prototype.$reportAxios = reportAxios;
Vue.prototype.$tpaSecuredAxios = tpaSecuredAxios;

// contextMenuMixin
//Vue.mixin(contextMenuMixin);
/************************ #Custom Mixins ****************************/

// import "@/assets/fonts/fa/css/font-awesome.min.css";

export default new Vue({
  el: "#app",
  i18n,
  router,
  store,
  render: h => h(App)
});
