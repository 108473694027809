import { validationMixin } from "vuelidate";
// For Inventory Product Search
import { isObject, isArray, isEqual, debounce } from "lodash";
import { mapGetters } from "vuex";

const { required, requiredIf, email, integer, decimal } = require("vuelidate/lib/validators");
export default {
  mixins: [validationMixin],
  validations() {
    return {
      input_textbox: {
        required: requiredIf(model => {
          if (this.input_type !== "textbox") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_textbox.trim().length > 0) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          }
        }),
        integer: (val, vm) => {
          if (this.input_type !== "textbox") {
            return true;
          } else {
            if (vm.input_validator == "integer") {
              if (["", null, undefined].indexOf(val) == -1) {
                let letters = /^[0-9]+$/;
                if (val.match(letters)) {
                  return true;
                  
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        },
        floatValidation(val, vm) {
          if (this.input_type !== "textbox") {
            return true;
          } else {
            if (vm.input_validator == "number") {
              if (["", null, undefined].indexOf(val) == -1) {
                let letters = /^[0-9.,]+$/;
                if (val.match(letters)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        },
        email: (val, vm) => {
          if (this.input_type !== "textbox") {
            return true;
          } else {
            if (vm.input_validator == "email") {
              let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (re.test(val)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        }
      },
      input_text: {
        required: requiredIf(model => {
          if (this.input_type !== "text") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_text.trim().length > 0) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          }
        })
      },
      input_checkbox: {
        required: requiredIf(model => {
          if (this.input_type !== "checkbox") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_checkbox === true) {
                return false;
              } else {
                return true;
              }
            } else if (this.input_type !== "checkbox") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_radio_button: {
        required: requiredIf(model => {
          if (this.input_type !== "radio_button") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_radio_button !== undefined) {
                return false;
              } else {
                return true;
              }
            } else if (this.input_type !== "radio_button") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_dropdownlist: {
        required: requiredIf(model => {
          if (this.input_type !== "dropdownlist") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_dropdownlist.trim().length > 0) {
                return false;
              } else {
                return true;
              }
            } else if (this.input_type !== "dropdownlist") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_calendar: {
        required: requiredIf(model => {
          if (this.input_type !== "calendar") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_calendar !== "") {
                return false;
              } else {
                return true;
              }
            } else if (this.input_type !== "calendar") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_smart_ddl: {
        required: requiredIf(model => {
          if (this.input_type !== "smart_ddl") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_smart_ddl !== undefined && this.input_smart_ddl !== null) {
                if (!!Object.keys(this.input_smart_ddl).length) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } else if (this.input_type !== "smart_ddl") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_inventory_product: {
        required: requiredIf(model => {
          if (this.input_type !== "inventory_product") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_inventory_product !== undefined && this.input_inventory_product !== null) {
                if (!!Object.keys(this.input_inventory_product).length) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } else if (this.input_type !== "inventory_product") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_contact_customer: {
        required: requiredIf(model => {
          if (this.input_type !== "contact_customer") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_contact_customer !== undefined && this.input_contact_customer !== null) {
                if (!!Object.keys(this.input_contact_customer).length) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } else if (this.input_type !== "contact_customer") {
              return false;
            } else {
              return false;
            }
          }
        })
      },
      input_contact_supplier: {
        required: requiredIf(model => {
          if (this.input_type !== "contact_supplier") {
            return false;
          } else {
            if (this.is_required) {
              if (this.input_contact_supplier !== undefined && this.input_contact_supplier !== null) {
                if (!!Object.keys(this.input_contact_supplier).length) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            } else if (this.input_type !== "contact_supplier") {
              return false;
            } else {
              return false;
            }
          }
        })
      }
    };
  },
  props: {
    touch: {
      required: false
    },
    filter_item: {
      required: false
    },
    value: {
      required: false
    },
    id: {
      required: true,
      type: String
    },
    name: {
      type: String,
      required: true
    },
    input_validator: {
      type: String,
      required: false
    },
    default_value: {
      // type: String,
      required: true
    },
    place_holder: {
      type: [String, Number, null, undefined],
      required: true
    },
    description: {
      // type: String,
      required: true
    },
    input_type: {
      type: String,
      default: "textbox"
    },
    smart_ddl_items: {
      type: Array,
      required: false
    },
    smart_ddl_type: {
      type: String,
      required: false,
      default: "dropdownlist"
    },
    type_inventory_product: {
      required: false
    },
    inventory_product_type_ids: {
      type: Array,
      required: false
    },
    inventory_product_group_ids: {
      type: Array,
      required: false
    },
    inventory_product_group_id_table: {
      type: Array,
      required: false
    },
    inventory_product_detail_description: {
      type: Boolean,
      required: false,
      default: false
    },
    // Just for Inventory Product Options Filtering by GroupIdTable
    cf: {
      type: Object,
      required: false
    },
    list_item_index: {
      required: false
    },
    values: {
      type: Array,
      required: false
    },
    is_required: {
      type: Boolean,
      required: false,
      default: false
    },
    checkbox_style: {
      type: String,
      required: false,
      default: "checkbox"
    },
    // Just For Project Validatins.. (Step Modal Custom Fields.)
    errorProperty: {
      type: String,
      required: false,
      default: "$error"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    returning_fields: {
      type: [Array, null],
      required: false,
      default: null
    },
    hideName: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      input_textbox: "",
      input_checkbox: undefined,
      input_radio_button: undefined,
      input_text: "",
      input_calendar: "",
      input_dropdownlist: "",
      input_smart_ddl: this.smart_ddl_type === "multi_select" ? [] : {},
      input_inventory_product: {},
      input_inventory_product_custom_description: null,
      // For Inventory Product DropdownList and Search
      initial_product_options: [],
      product_options: [],
      search_text: {},
      search_key: "",
      didSearch: false,
      inventory_product_options_loaded: false,
      // Contact Inputs
      input_contact_customer: {},
      input_contact_supplier: {},
      return_fields: ["id", "name", "auto_id"]
    };
  },
  created() {
    if (this.input_type === "inventory_product" && this.type_inventory_product === "dropdownlist") {
      // inventory_product_type_ids we will get type_ids via props.. and send to API
      this.$store
        .dispatch("fetch", {
          endpoint: this.$store.state.inventory_products.endpoint + "quick_search",
          params: {
            params: {
              inventory_product_type_ids: this.inventory_product_type_ids,
              inventory_product_group_ids: this.inventory_product_group_ids,
              // status: "in_stock",
              status_exclude: ["inactive"],
              page_size : 300
            }
          }
        })
        .then(product_response => {
          let product_options = product_response[this.$store.state.inventory_products.fetchKey];

          let products = [];
          if (product_options.length > 0) {
            product_options.forEach(product => {
              let product_object = {};

              if (product.has_vars) {
                product.vars.forEach(variation => {
                  product_object = {
                    label: `${variation.auto_id} ${variation.name || product.title}`,
                    auto_id: variation.auto_id,
                    name: variation.name || product.title,
                    id: product.id
                  };

                  if (product?.hasOwnProperty("group_ids")) {
                    product_object.group_ids = product.group_ids;
                  }

                  if (product?.hasOwnProperty("project_setting") && isObject(product.project_setting)) {
                    if (product?.project_setting?.hasOwnProperty("styler") && isObject(product.project_setting.styler)) {
                      product_object.styler = product.project_setting.styler;
                    }
                  }

                  products.push(product_object);
                });
              } else {
                product_object = {
                  label: `${product.auto_id} ${product.name}`,
                  auto_id: product.auto_id,
                  name: product.name,
                  id: product.id
                };
                if (product?.hasOwnProperty("group_ids")) {
                  product_object.group_ids = product.group_ids;
                }
                if (product?.hasOwnProperty("project_setting") && isObject(product.project_setting)) {
                  if (product?.project_setting?.hasOwnProperty("styler") && isObject(product.project_setting.styler)) {
                    product_object.styler = product.project_setting.styler;
                  }
                }

                products.push(product_object);
              }
            });
            this.product_options = products;
            this.initial_product_options = products;
          }
          this.inventory_product_options_loaded = true;
          this.filterInventoryProductItemsByGroupIdTable(this.inventory_product_group_id_table);
        });
    }

    this.setFields();

    if (this.returning_fields !== null) {
      this.return_fields = this.returning_fields;
    }
  },
  watch: {
    input_textbox(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    input_checkbox(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    input_radio_button(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    input_text(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    input_calendar(val) {
      if (val !== null) {
        this.$emit(
          "input",
          val
            .toString()
            .split(" ")
            .slice(0, 5)
            .join(" ")
        );
        this.$emit(
          "change",
          val
            .toString()
            .split(" ")
            .slice(0, 5)
            .join(" ")
        );
      }
    },
    input_dropdownlist(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    input_smart_ddl(newValue, oldValue) {
      this.$emit("input", newValue);
      this.$emit("change", { newValue: newValue, oldValue: oldValue });
    },
    input_inventory_product(val) {
      this.$emit("input", val);
      this.$emit("change", val);
      if (val == null || val == undefined) {
        this.$emit("input", {});
        this.$emit("change", {});
        this.$emit("refreshUI", true);
      }
    },
    input_contact_customer(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    input_contact_supplier(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    checkValidation(validationStatus) {
      this.$emit("validationEvent", {
        key: this.id,
        status: validationStatus
      });
    },
    touch(val) {
      if (val === true) {
        if (!this.filter_item) {
          this.$v["input_" + this.input_type]?.$touch();
        }
      }
    },
    inventory_product_group_id_table(groupIdTable) {
      this.filterInventoryProductItemsByGroupIdTable(groupIdTable);
    },
    value(val) {
      // this.setFields();
    }
  },
  computed: {
    smartDDLClasses() {
      return {
        "is-invalid-custom": this.$v.input_smart_ddl[this.errorPropertyDecisionMaker],
        sm: (this?.smart_ddl_type == "multi_select" && this?.input_smart_ddl.length === 0) || this?.smart_ddl_type != "multi_select"
      };
    },
    smartDDLItems() {
      return this.smart_ddl_items?.map(i => {
        return {
          ...i,
          label: i.name
        };
      });
    },
    checkValidation() {
      return this.$v.$invalid;
    },
    errorPropertyDecisionMaker() {
      if (["inventory_product", "dropdownlist"].indexOf(this.input_type) > -1) {
        return this.value !== undefined && this.value !== null ? "$invalid" : "$error";
      } else {
        return this.touch && this.value !== undefined && this.value !== null ? "$invalid" : "$error";
      }
    },
    ...mapGetters({
      measurementList: "inventory_products/measurementList"
    }),
    placeholder() {
      if ([null, undefined].indexOf(this.place_holder) === -1) {
        return this.place_holder.toString();
      } else {
        return "";
      }
    },
    descriptionAvailable() {
      return this.input_inventory_product_custom_description?.length > 0 || this.description.length > 0;
    },
    checkboxFieldAvailable() {
      return this.input_type == "checkbox" && ["checkbox", "", null].indexOf(this.checkbox_style) > -1;
    },
    switchFieldAvailable() {
      return this.input_type == "checkbox" && this.checkbox_style == "toggle";
    },
    tooltip_description() {
      const description = this.input_inventory_product_custom_description || this.description;
      return description?.replaceAll("\n", "<br>");
    }
  },
  methods: {
    tooltipShowEvent(bvEvent) {
      const inventoryProductType = this.input_type === "inventory_product";
      const materialDescription = bvEvent?.target?.id === "description_Matériel";
      const hasCustomDescription = this.inventory_product_detail_description;
      const hasProduct = isObject(this.input_inventory_product) && this.input_inventory_product?.id;
      if (inventoryProductType && materialDescription && hasCustomDescription && hasProduct) {
        this.$store
          .dispatch("fetchOne", {
            endpoint: this.$store.state.inventory_products.endpoint,
            id: this.input_inventory_product.id
          })
          .then(response => {
            let product = response.product;
            let products = [];

            let product_object = {};

            if (product.has_vars) {
              product.vars?.forEach(variation => {
                product_object = {
                  label: `${variation.auto_id} ${variation.name || product.title}`,
                  auto_id: variation.auto_id,
                  name: variation.name || product.title,
                  id: product.id
                };
                products.push(product_object);
              });
            } else {
              if (product?.hasOwnProperty("vars") && isArray(product.vars) && product.vars.length > 0) {
                let variation = product.vars[0];

                // Getting Meesurements
                let measurements = Object.keys(variation).filter(i => i.startsWith("m_") == true && i.endsWith("_um") == false);
                let description = [];
                measurements.forEach(key => {
                  let key_text = this.measurementList.filter(i => i.name === key.split("_")[1])[0].text || key;
                  description.push({
                    key : key_text,
                    value : `${variation[key]} ${variation[key + "_um"]}`
                  })
                });

                // Getting Stocks
                if (variation?.hasOwnProperty("stocks") && isArray(variation.stocks) && variation.stocks.length > 0) {
                  let stock_count = variation.stocks.reduce((a, b) => +a + +b.quantity, 0);
                  description.push({
                    key : this.$t("components.custom_fields.stocks"),
                    value : this._intlNumberFormat(stock_count,2)
                  })
                }

                const htmlFormatter = (di) => `<div class="tooltip_description_line_container"><span>${di.key}</span><span>${di.value}</span></div>`;
                const htmlRender = description?.map(htmlFormatter);
                this.input_inventory_product_custom_description = htmlRender?.join("")
              }
            }
          });
      } else {
        this.input_inventory_product_custom_description = null;
      }
    },
    setFields() {
      // if (this.value || isNumber(this.value)) {
      if (this.input_type === "textbox") {
        this.input_textbox = this.value?.toString() || "";
      } else if (this.input_type === "checkbox") {
        this.input_checkbox = this.value;
      } else if (this.input_type === "radio_button") {
        this.input_radio_button = this.value;
      } else if (this.input_type === "text") {
        this.input_text = this.value;
      } else if (this.input_type === "calendar") {
        this.input_calendar = this.newDate(this.value);
      } else if (this.input_type === "dropdownlist") {
        this.input_dropdownlist = this.value;
      } else if (this.input_type === "smart_ddl") {
        // Try To Get Full Object XXXX Asks Harun
        // Setting initialValue for Smart DDL
        this.input_smart_ddl = this.value;
      } else if (this.input_type === "inventory_product") {
        if(isEqual(this.value, {})) {
          this.input_inventory_product = null
          return
        }
        this.input_inventory_product = this.value;
      } else if (this.input_type === "contact_customer") {
        this.input_contact_customer = this.value;
      } else if (this.input_type === "contact_supplier") {
        this.input_contact_supplier = this.value;
      }

      if (!this.value && this.input_type === "smart_ddl" && this.default_value && (this.default_value !== null || this.default_value !== undefined)) {
        const defaultValue = this.smart_ddl_items.find(i => i.value === this.default_value);
        if (this.smart_ddl_type.includes("multi_select")) {
          if (defaultValue) {
            this.input_smart_ddl = [defaultValue];
          } else {
            this.input_smart_ddl = [];
          }
        } else {
          this.input_smart_ddl = defaultValue || null;
        }
      }

      //! Value Check
      if (this.input_type === "smart_ddl" && this.smart_ddl_type === "multi_select_checkbox" && !isArray(this.value)) {
        this.input_smart_ddl = [];
      }
    },
    onSearch(search, loading) {
      if (search.length < 3) {
        this.didSearch = false;
        this.product_options = [];
      }
      if (search.length >= 3) {
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      vm.product_options = [];
      vm.$store
        .dispatch("fetch", {
          endpoint: vm.$store.state.inventory_products.endpoint + "quick_search",
          params: {
            params: {
              search_text: search,
              inventory_product_type_ids: vm.inventory_product_type_ids,
              inventory_product_group_ids: vm.inventory_product_group_ids,
              // status: "in_stock",
              status_exclude: ["inactive"]
            }
          }
        })
        .then(response => {
          vm.didSearch = true;
          // vm.product_options =
          let product_options = response.products;
          let products = [];

          if (product_options.length > 0) {
            product_options.forEach(product => {
              let product_object = {};

              if (product.has_vars) {
                product.vars.forEach(variation => {
                  product_object = {
                    label: `${variation.auto_id} ${variation.name || product.title}`,
                    auto_id: variation.auto_id,
                    name: variation.name || product.title,
                    id: product.id,
                    quantity : product.quantity
                  };
                  products.push(product_object);
                });
              } else {
                product_object = {
                  label: `${product.auto_id} ${product.name}`,
                  auto_id: product.auto_id,
                  name: product.name,
                  id: product.id,
                  quantity : product.quantity
                };
                products.push(product_object);
              }
            });
            vm.product_options = products;
          }
        });
    }, 1000),
    clearSearchBox() {
      this.$forceUpdate();
      this.search_text = {};
      this.product_options = [];
    },
    setInventoryProduct(inventory_product) {
      this.input_inventory_product = inventory_product;
    },
    filterInventoryProductItemsByGroupIdTable(groupIdTable) {
      if (this.inventory_product_options_loaded === true) {
        if (this.initial_product_options.length > 0) {
          /*** ##1 CF içinde Styler Var mı diye bakılır ***************/
          let product_items = [];

          // Iterating DDL Items
          this.initial_product_options.forEach(product_item => {
            // Let's begin with false
            let productItemWillBeShown = true;

            // If product_item has Styler
            if (product_item?.hasOwnProperty("styler")) {
              // 1 - If styler has hide default value
              if (product_item?.styler?.hasOwnProperty("hide")) {
                productItemWillBeShown = !product_item.styler.hide;
              }

              // 2 - If styler has show_ats
              if (product_item?.styler?.hasOwnProperty("show_ats")) {
                // keep them all in variable for easy programming
                let show_ats = product_item.styler.show_ats;

                // If CF has any group_ids in groupIdTable CF should be shown
                // let shouldShow = show_ats.some(h => this.groupIdTable.indexOf(h.taxon) > -1);
                let shouldShow = show_ats.some(h => {
                  let taxon = h.taxon;
                  if (
                    this?.cf?.hasOwnProperty("is_list_item") &&
                    this.cf.is_list_item &&
                    this.list_item_index !== null &&
                    this.list_item_index !== undefined
                  ) {
                    taxon = `${h.taxon}_${this.list_item_index}`;
                  }
                  return groupIdTable.indexOf(taxon) > -1;
                });

                // If Cf has...
                if (shouldShow) {
                  // But at the same time, CF would has other group_ids in groupIdTable for Hiding
                  if (product_item?.styler?.hasOwnProperty("hide_ats")) {
                    // keep them all in variable for easy programming
                    let hide_ats = product_item.styler.hide_ats;
                    // If CF has any group_ids in groupIdTable CF should be hidden
                    // let shouldHide = hide_ats.some(h => this.groupIdTable.indexOf(h.taxon) > -1);
                    let shouldHide = hide_ats.some(h => {
                      let taxon = h.taxon;
                      if (
                        this?.cf?.hasOwnProperty("is_list_item") &&
                        this.cf.is_list_item &&
                        this.list_item_index !== null &&
                        this.list_item_index !== undefined
                      ) {
                        taxon = `${h.taxon}_${this.list_item_index}`;
                      }
                      return groupIdTable.indexOf(taxon) > -1;
                    });

                    // If Cf has...
                    if (shouldHide) {
                      // We Need to find out which one should be decision-maker
                      let showMaxAuth = Math.max(...show_ats.map(h => h.authority));
                      let hideMaxAuth = Math.max(...hide_ats.map(s => s.authority));

                      // Compare Shows and Hides Max items.
                      if (showMaxAuth >= hideMaxAuth) {
                        productItemWillBeShown = true;
                      } else {
                        productItemWillBeShown = false;
                      }
                    } else {
                      // @override : Gizlemek için gereken olasılık yok true yapacağız..
                      productItemWillBeShown = true;
                    }
                  } else {
                    // @override : shouldShow Var olduğu hide_ats olmadığı için  true yapacağız..
                    productItemWillBeShown = true;
                  }
                }
              }

              // 3 - If styler has hide_ats
              if (product_item?.styler?.hasOwnProperty("hide_ats")) {
                let hide_ats = product_item.styler.hide_ats;
                // let shouldHide = hide_ats.some(h => this.groupIdTable.indexOf(h.taxon) > -1);
                let shouldHide = hide_ats.some(h => {
                  let taxon = h.taxon;
                  if (
                    this?.cf?.hasOwnProperty("is_list_item") &&
                    this.cf.is_list_item &&
                    this.list_item_index !== null &&
                    this.list_item_index !== undefined
                  ) {
                    taxon = `${h.taxon}_${this.list_item_index}`;
                  }
                  return groupIdTable.indexOf(taxon) > -1;
                });

                if (shouldHide) {
                  // Eğer Aynı zamanda Show Ats içerisinde var mı?
                  if (product_item?.styler?.hasOwnProperty("show_ats")) {
                    let show_ats = product_item.styler.show_ats;
                    // let shouldShow = show_ats.some(h => this.groupIdTable.indexOf(h.taxon) > -1);
                    let shouldShow = show_ats.some(h => {
                      let taxon = h.taxon;
                      if (
                        this?.cf?.hasOwnProperty("is_list_item") &&
                        this.cf.is_list_item &&
                        this.list_item_index !== null &&
                        this.list_item_index !== undefined
                      ) {
                        taxon = `${h.taxon}_${this.list_item_index}`;
                      }
                      return groupIdTable.indexOf(taxon) > -1;
                    });
                    if (shouldShow) {
                      let showMaxAuth = Math.max(...show_ats.map(s => s.authority));
                      let hideMaxAuth = Math.max(...hide_ats.map(h => h.authority));
                      if (showMaxAuth >= hideMaxAuth) {
                        productItemWillBeShown = true;
                      } else {
                        productItemWillBeShown = false;
                      }
                    } else {
                      productItemWillBeShown = false;
                    }
                  } else {
                    // Default value is still false... This line will never run!!!
                    productItemWillBeShown = false;
                  }
                }
              }
            } else {
              // No Styler => show...
              productItemWillBeShown = true;
            }

            if (productItemWillBeShown) {
              product_items.push(product_item);
            }
          });

          this.product_options = product_items;
          // check active value is in product_items
          if (this.input_inventory_product !== null && isObject(this.input_inventory_product)) {
            let item_index = product_items.findIndex(i => i.id == this.input_inventory_product.id);
            if (item_index === -1) {
              this.input_inventory_product = null;
            }
          }
        } else {
          this.product_options = [];
        }
      }
    }
  },
  mounted() {
    if (this.value === null) {
      switch (this.input_type) {
        case "checkbox":
        case "textbox":
        case "text":
          this[`input_${this.input_type}`] = this.default_value;
          break;
        case "radio_button":
        case "dropdownlist":
          if (isArray(this.values) && this.values?.length > 0 && isArray(this.default_value) && this.default_value?.length > 0) {
            this[`input_${this.input_type}`] = [...this.default_value];
          }
          break;
        case "smart_ddl":
          if (["multi_select_checkbox", "multi_select"].indexOf(this.smart_ddl_type) > -1) {
            this.input_smart_ddl = this.smart_ddl_items?.filter(i => i?.primary) || [];
          } else {
            const defaultExists = this.smart_ddl_items?.find(i => i?.primary);
            if (defaultExists) {
              this.input_smart_ddl = defaultExists;
            } else {
              this.input_smart_ddl = null;
            }
          }
          break;
        case "calendar":
          if (this.default_value !== "" && this.default_value !== null) {
            this.input_calendar = this.newDate(this.default_value);
          }
          break;
        default:
          break;
      }
    }
    this.$emit("validationEvent", {
      key: this.id,
      status: this.$v.$invalid
    });
  },
  beforeDestroy() {
    if (isObject(this.cf)) {
      this.$emit("remove-dependencies", `cf_${this.cf?.id}`);
    }
  }
}
